import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';
import { setAuthToken } from '../utils/auth';



const BookingModal = ({ 
  isOpen, 
  onClose, 
  context, // { type: 'event' | 'live_music', title: string, date?: string, option?: string }
  vendorId,
  onLoginRequired
}) => {
  const [bookingData, setBookingData] = useState({
    date: '',
    time: '',
    number_of_people: 1,
    special_requests: ''
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (!isOpen) return;

    // Initialize booking data based on context
    let specialRequests = `Event: ${context.title}`;
    if (context.option) {
      specialRequests += `\nOption Selected: ${context.option}`;
    }

    if (context.type === 'live_music' && context.date) {
      const eventDate = new Date(context.date);
      setBookingData(prev => ({
        ...prev,
        date: eventDate.toISOString().split('T')[0],
        time: eventDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }),
        special_requests: specialRequests
      }));
    } else {
      setBookingData(prev => ({
        ...prev,
        special_requests: specialRequests
      }));
    }
  }, [isOpen, context]);

  const validateBooking = () => {
    const today = new Date();
    const selectedDate = new Date(`${bookingData.date}T${bookingData.time}`);
    
    if (selectedDate <= today) {
      setError('Please select a future date and time');
      return false;
    }
    
    if (bookingData.number_of_people < 1) {
      setError('Number of people must be at least 1');
      return false;
    }
    
    return true;
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsSubmitting(true);

    const token = localStorage.getItem('token');
    if (!token) {
        onLoginRequired();
        setIsSubmitting(false);
        return;
    }

    if (!validateBooking()) {
        setIsSubmitting(false);
        return;
      }

      try {
        setAuthToken(token);
        const response = await axios.post(`/api/bookings/customer/${vendorId}`, {
          ...bookingData,
          context_type: context.type,
          context_reference: context.type === 'live_music' ? context.eventId : null
        });
    
        setSuccess('Booking submitted successfully!');
        setTimeout(() => {
          onClose();
          setSuccess('');
        }, 2000);
      } catch (error) {
        setError(error.response?.data?.error || 'Error submitting booking');
      } finally {
        setIsSubmitting(false);
      }
    };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={(e) => e.target.className === 'modal-overlay' && onClose()}>
      <div className="modal-content booking-modal">
        <h2>{context.type === 'live_music' ? 'Book Live Event' : 'Book Event'}</h2>
        
        <div className="event-details">
          <h3>{context.title}</h3>
          {context.option && <p>Selected Option: {context.option}</p>}
          {context.type === 'live_music' && (
            <p>Event Date: {new Date(context.date).toLocaleDateString()}</p>
          )}
        </div>

        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Date:</label>
            <input
              type="date"
              value={bookingData.date}
              onChange={(e) => setBookingData({ ...bookingData, date: e.target.value })}
              min={new Date().toISOString().split('T')[0]}
              disabled={context.type === 'live_music'}
              required
            />
          </div>

          <div className="form-group">
            <label>Time:</label>
            <input
              type="time"
              value={bookingData.time}
              onChange={(e) => setBookingData({ ...bookingData, time: e.target.value })}
            //   disabled={context.type === 'live_music'}
              required
            />
          </div>

          <div className="form-group">
            <label>Number of People:</label>
            <input
              type="number"
              value={bookingData.number_of_people}
              onChange={(e) => setBookingData({ ...bookingData, number_of_people: parseInt(e.target.value) })}
              min="1"
              required
            />
          </div>

          <div className="form-group">
            <label>Special Requests:</label>
            <textarea
              value={bookingData.special_requests}
              onChange={(e) => setBookingData({ ...bookingData, special_requests: e.target.value })}
              rows={4}
              className="special-requests"
            //   readOnly={context.type === 'live_music' || context.option}
            />
          </div>

          <div className="button-group">
          <button type="button" className="cancel-btn" onClick={onClose}>
              Cancel
            </button>
          <button 
            type="submit" 
            className={`submit-btn ${isSubmitting ? 'loading' : ''}`} 
            disabled={isSubmitting || !!success}
            >
            {isSubmitting ? '' : 'Confirm Booking'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingModal;