import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/main.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faMapMarkerAlt, 
  faPhone, 
  faUser, 
  faUsers, 
  faChartLine, 
  faGamepad, 
  faPlug,
  faShare
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

library.add(
  faMapMarkerAlt, 
  faPhone, 
  faWhatsapp, 
  faUser, 
  faUsers, 
  faChartLine, 
  faGamepad, 
  faPlug,
  faShare
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.addEventListener('controllerchange', () => {
//     console.log('Service Worker controller changed. Reloading page...');
//     window.location.reload();
//   });
// }

serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    console.log('New service worker version detected.');
    if (window.confirm('New version available! Ready to update?')) {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
  },
});

reportWebVitals(console.log);