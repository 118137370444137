import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import axios from '../../utils/axiosConfig';
import { setAuthToken } from '../../utils/auth';
import { Calendar, Trash2, Edit, Plus, ArrowUp, ArrowDown } from 'lucide-react';

function EventsManagement() {
  const [sections, setSections] = useState([]);
  const [liveEvents, setLiveEvents] = useState([]);
  const [activeView, setActiveView] = useState('sections');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingSection, setEditingSection] = useState(null);
  const [editingEvent, setEditingEvent] = useState(null);

  const [uploadingOptionId, setUploadingOptionId] = useState(null);
  const [uploadingEventId, setUploadingEventId] = useState(null);
  const [actionMessages, setActionMessages] = useState({});
  const [updatingOptionId, setUpdatingOptionId] = useState(null);

  const [newOption, setNewOption] = useState({
    sectionId: null,
    subtitle: '',
    file: null
  });

  // Form states
  const [newSection, setNewSection] = useState({
    title: '',
    description: ''
  });

  const [newEvent, setNewEvent] = useState({
    title: '',
    description: '',
    event_date: '',
    event_time: '',
    file: null
  });

    const [editingOption, setEditingOption] = useState(null);
    const [editedOption, setEditedOption] = useState({
    subtitle: '',
    file: null
    });



  const debouncedFetchData = useCallback(
    debounce(async () => {
      setIsLoading(true);
      try {
        const [sectionsRes, eventsRes] = await Promise.all([
          axios.get('/api/vendors/event-sections'),
          axios.get('/api/vendors/live-events')
        ]);
        setSections(sectionsRes.data);
        setLiveEvents(eventsRes.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching events data:', error);
        setError('Failed to fetch events data');
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
    }
    debouncedFetchData();
    
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [sectionsRes, eventsRes] = await Promise.all([
        axios.get('/api/vendors/event-sections'),
        axios.get('/api/vendors/live-events')
      ]);
      setSections(sectionsRes.data);
      setLiveEvents(eventsRes.data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch events data');
      console.error(err);
    } finally {
     setIsLoading(false);
    }
  };

  const handleEditSection = (section) => {
    if (editingSection?.id === section.id) {
      setEditingSection(null);
      setNewSection({ title: '', description: '' });
    } else {
      setEditingSection(section);
      setNewSection({
        title: section.title,
        description: section.description || ''
      });
      // Scroll to top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const handleEditEvent = (event) => {
    if (editingEvent?.id === event.id) {
      setEditingEvent(null);
      setNewEvent({
        title: '',
        description: '',
        event_date: '',
        event_time: '',
        file: null
      });
    } else {
      setEditingEvent(event);
      const eventDate = new Date(event.event_date);
      setNewEvent({
        title: event.title,
        description: event.description || '',
        event_date: eventDate.toISOString().split('T')[0],
        event_time: eventDate.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' }),
        file: null
      });
    }
  };

//   const handleDragEnd = async (result, type) => {
//     if (!result.destination) return;

//     const items = type === 'sections' ? [...sections] : [...liveEvents];
//     const [reorderedItem] = items.splice(result.source.index, 1);
//     items.splice(result.destination.index, 0, reorderedItem);

//     if (type === 'sections') {
//       setSections(items);
//       try {
//         await axios.put('/api/vendors/event-sections/reorder', {
//           sectionOrder: items.map((item, index) => ({ id: item.id, sort_order: index }))
//         });
//       } catch (error) {
//         console.error('Error reordering sections:', error);
//       }
//     }
//   };

const handleReorder = async (sectionId, direction) => {
    try {
      const currentIndex = sections.findIndex(section => section.id === sectionId);
      if ((direction === 'up' && currentIndex === 0) || 
          (direction === 'down' && currentIndex === sections.length - 1)) {
        return;
      }

      await axios.put(`/api/vendors/event-sections/${sectionId}`, {
        sort_order: direction === 'up' ? currentIndex - 1 : currentIndex + 1
      });
      
      fetchData(); // Refresh the list
    } catch (error) {
      setError('Failed to reorder sections');
      console.error(error);
    }
  };

  const handleSectionSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (editingSection) {
        await axios.put(`/api/vendors/event-sections/${editingSection.id}`, newSection);
      } else {
        await axios.post('/api/vendors/event-sections', newSection);
      }
      setNewSection({ title: '', description: '' });
      setEditingSection(null);
      await debouncedFetchData();
    } catch (error) {
      setError(error.response?.data?.error || 'Error saving section');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEventSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const dateTime = `${newEvent.event_date}T${newEvent.event_time}:00`;
    
    Object.keys(newEvent).forEach(key => {
      if (key === 'event_date') {
        formData.append('event_date', dateTime);
      } else if (key === 'event_time') {
        return;
      } else if (key === 'file' && newEvent.file) {
        formData.append('file', newEvent.file);
      } else {
        formData.append(key, newEvent[key]);
      }
    });
  
    const eventId = editingEvent?.id || 'new';
    setUploadingEventId(eventId);
  
    try {
      if (editingEvent) {
        await axios.put(`/api/vendors/live-events/${editingEvent.id}`, formData);
      } else {
        await axios.post('/api/vendors/live-events', formData);
      }
      setNewEvent({
        title: '',
        description: '',
        event_date: '',
        event_time: '',
        file: null
      });
      setEditingEvent(null);
      setActionMessage(eventId, 'success', `Event ${editingEvent ? 'updated' : 'added'} successfully`);
      await debouncedFetchData();
    } catch (error) {
      setActionMessage(eventId, 'error', error.response?.data?.error || 'Error saving event');
    } finally {
      setUploadingEventId(null);
    }
  };

  if (isLoading && !sections.length && !liveEvents.length) {
    return (
      <div className="events-management">
        <div className="loader">Loading events management...</div>
      </div>
    );
  }


  const handleAddOption = async (sectionId) => {
    if (!newOption.subtitle.trim()) {
      setError('Subtitle is required');
      return;
    }
  
    setUploadingOptionId(sectionId);
    const formData = new FormData();
    formData.append('subtitle', newOption.subtitle);
    if (newOption.file) {
      formData.append('file', newOption.file);
    }
  
    try {
      await axios.post(`/api/vendors/event-sections/${sectionId}/options`, formData);
      setNewOption({
        sectionId: null,
        subtitle: '',
        file: null
      });
      setActionMessage(sectionId, 'success', 'Option added successfully');
      fetchData();
    } catch (error) {
      setActionMessage(sectionId, 'error', error.response?.data?.error || 'Error adding option');
    } finally {
      setUploadingOptionId(null);
    }
  };

  const handleDeleteSection = async (sectionId) => {
    if (window.confirm('Are you sure you want to delete this section?')) {
      try {
        await axios.delete(`/api/vendors/event-sections/${sectionId}`);
        fetchData();
      } catch (error) {
        setError(error.response?.data?.error || 'Error deleting section');
      }
    }
  };

  const handleDeleteEvent = async (eventId) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        await axios.delete(`/api/vendors/live-events/${eventId}`);
        fetchData();
      } catch (error) {
        setError(error.response?.data?.error || 'Error deleting event');
      }
    }
  };

  const handleEditOption = (option) => {
    if (editingOption?.id === option.id) {
      setEditingOption(null);
      setEditedOption({ subtitle: '', file: null });
    } else {
      setEditingOption(option);
      setEditedOption({
        subtitle: option.subtitle,
        file: null // Can't pre-fill file input
      });
    }
  };
  
  const handleUpdateOption = async (sectionId, optionId) => {
    const formData = new FormData();
    formData.append('subtitle', editedOption.subtitle);
    if (editedOption.file) {
      formData.append('file', editedOption.file);
    }
  
    setUpdatingOptionId(optionId);
  
    try {
      await axios.put(`/api/vendors/event-options/${optionId}`, formData);
      setEditingOption(null);
      setEditedOption({ subtitle: '', file: null });
      setActionMessage(optionId, 'success', 'Option updated successfully');
      fetchData();
    } catch (error) {
      setActionMessage(optionId, 'error', error.response?.data?.error || 'Error updating option');
    } finally {
      setUpdatingOptionId(null);
    }
  };
  
  // Update the handleDeleteOption function
  const handleDeleteOption = async (sectionId, optionId) => {
    if (window.confirm('Are you sure you want to delete this option?')) {
      try {
        // Update the API endpoint to match the backend route
        await axios.delete(`/api/vendors/event-options/${optionId}`);
        fetchData();
      } catch (error) {
        setError(error.response?.data?.error || 'Error deleting option');
      }
    }
  };

  const setActionMessage = (id, type, message) => {
    setActionMessages(prev => ({
      ...prev,
      [id]: { type, message }
    }));
    // Clear message after 3 seconds
    setTimeout(() => {
      setActionMessages(prev => {
        const newMessages = { ...prev };
        delete newMessages[id];
        return newMessages;
      });
    }, 3000);
  };

  return (
    <div className="events-management">
      <div className="tab-buttons">
        <button
          className={`tab-button ${activeView === 'sections' ? 'active' : ''}`}
          onClick={() => setActiveView('sections')}
        >
          Event Occasions
        </button>
        <button
          className={`tab-button ${activeView === 'live' ? 'active' : ''}`}
          onClick={() => setActiveView('live')}
        >
          Live Events
        </button>
      </div>
  
      {error && <div className="error-message">{error}</div>}
      {isLoading && <div className="loading-overlay">Processing...</div>}
  
      {activeView === 'sections' ? (
        <div className="sections-view">
          <form onSubmit={handleSectionSubmit} className="section-form">
            <h3>{editingSection ? 'Edit Section' : 'Add New Section'}</h3>
            <input
              type="text"
              placeholder="Section Title"
              value={newSection.title}
              onChange={(e) => setNewSection({...newSection, title: e.target.value})}
              required
            />
            <textarea
              placeholder="Description (optional)"
              value={newSection.description}
              onChange={(e) => setNewSection({...newSection, description: e.target.value})}
            />
            <button type="submit">{editingSection ? 'Update Section' : 'Add Section'}</button>
          </form>
  
          <div className="sections-list">
            {sections.map((section, index) => (
              <div key={section.id} className="section-item">
                <div className="section-header">
                  <h4>{section.title}</h4>
                  <div className="order-controls">
                    <button
                      onClick={() => handleReorder(section.id, 'up')}
                      disabled={index === 0}
                    >
                      <ArrowUp size={16} />
                    </button>
                    <button
                      onClick={() => handleReorder(section.id, 'down')}
                      disabled={index === sections.length - 1}
                    >
                      <ArrowDown size={16} />
                    </button>
                  </div>
                </div>
                
                <p>{section.description}</p>
                
                <div className="options-list">
                    {section.options?.map(option => (
                        <div key={option.id} className="option-item">
                        {editingOption?.id === option.id ? (
                            // Edit mode
                            <div className="option-edit-form">
                            <input
                              type="text"
                              value={editedOption.subtitle}
                              onChange={(e) => setEditedOption({
                                ...editedOption,
                                subtitle: e.target.value
                              })}
                              placeholder="Option Subtitle"
                              disabled={updatingOptionId === option.id}
                            />
                            <input
                              type="file"
                              accept="image/*,application/pdf"
                              onChange={(e) => setEditedOption({
                                ...editedOption,
                                file: e.target.files[0]
                              })}
                              disabled={updatingOptionId === option.id}
                            />
                            <div className="option-edit-controls">
                              <div className="option-action-container">
                                <button 
                                  onClick={() => handleUpdateOption(section.id, option.id)}
                                  disabled={updatingOptionId === option.id}
                                  className="save-btn-event"
                                >
                                  {updatingOptionId === option.id ? (
                                    <span className="spinner">⌛</span>
                                  ) : (
                                    'Save'
                                  )}
                                </button>
                                {actionMessages[option.id] && (
                                  <span className={`action-message ${actionMessages[option.id].type}`}>
                                    {actionMessages[option.id].message}
                                  </span>
                                )}
                              </div>
                              <button 
                                onClick={() => handleEditOption(option)}
                                disabled={updatingOptionId === option.id}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : (
                            // View mode
                            <>
                            <div className="option-header">
                                <h5>{option.subtitle}</h5>
                                <div className="option-controls">
                                <button onClick={() => handleEditOption(option)}>
                                    <Edit size={16} />
                                    Edit
                                </button>
                                <button onClick={() => handleDeleteOption(section.id, option.id)}>
                                    <Trash2 size={16} />
                                    Delete
                                </button>
                                </div>
                            </div>
                            {option.file_url && (
                               <div className="file-preview">
                               {option.file_type === 'image' ? (
                                 <img src={option.file_url} alt={option.subtitle} className="preview-image" />
                               ) : (
                                 <div className="pdf-preview">
                                   {option.thumbnail_url ? (
                                     <img src={option.thumbnail_url} alt={`${option.subtitle} preview`} className="pdf-thumbnail" />
                                   ) : (
                                     <div className="pdf-icon">PDF</div>
                                   )}
                                   <a href={option.file_url} target="_blank" rel="noopener noreferrer" className="view-pdf-link">
                                     View PDF
                                   </a>
                                 </div>
                               )}
                             </div>
                            )}
                            </>
                        )}
                        </div>
                    ))}
                    </div>
  
                <div className="section-controls">
                  <button onClick={() => handleEditSection(section)}>
                    <Edit size={16} />
                    {editingSection?.id === section.id ? 'Cancel Edit' : 'Edit section'}
                  </button>
                  <button onClick={() => handleDeleteSection(section.id)}>
                    <Trash2 size={16} /> Delete section
                  </button>
                </div>
  
                <div className="add-option">
                    <input
                        type="text"
                        placeholder="Option Subtitle"
                        value={newOption.sectionId === section.id ? newOption.subtitle : ''}
                        onChange={(e) => setNewOption({
                        sectionId: section.id,
                        subtitle: e.target.value,
                        file: newOption.file
                        })}
                        disabled={uploadingOptionId === section.id}
                    />
                    <input
                        type="file"
                        accept="image/*,application/pdf"
                        onChange={(e) => setNewOption({
                        ...newOption,
                        sectionId: section.id,
                        file: e.target.files[0]
                        })}
                        disabled={uploadingOptionId === section.id}
                    />
                    <div className="option-action-container">
                        <button 
                        onClick={() => handleAddOption(section.id)}
                        disabled={uploadingOptionId === section.id}
                        >
                        {uploadingOptionId === section.id ? (
                            <span className="spinner">⌛</span>
                        ) : (
                            <>
                            <Plus size={16} /> Add Option
                            </>
                        )}
                        </button>
                        {actionMessages[section.id] && (
                        <span className={`action-message ${actionMessages[section.id].type}`}>
                            {actionMessages[section.id].message}
                        </span>
                        )}
                    </div>
                    </div>

              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="live-events-view">
          <form onSubmit={handleEventSubmit} className="event-form">
            <h3>{editingEvent ? 'Edit Event' : 'Add New Event'}</h3>
            <input
              type="text"
              placeholder="Event Title"
              value={newEvent.title}
              onChange={(e) => setNewEvent({...newEvent, title: e.target.value})}
              required
            />
            <textarea
              placeholder="Description"
              value={newEvent.description}
              onChange={(e) => setNewEvent({...newEvent, description: e.target.value})}
            />
            <input
              type="date"
              value={newEvent.event_date}
              onChange={(e) => setNewEvent({...newEvent, event_date: e.target.value})}
              required
            />
            <input
              type="time"
              value={newEvent.event_time}
              onChange={(e) => setNewEvent({...newEvent, event_time: e.target.value})}
              required
            />
            <input
              type="file"
              accept="image/*,application/pdf"
              onChange={(e) => setNewEvent({...newEvent, file: e.target.files[0]})}
            />
           <div className="event-action-container">
                <button 
                    type="submit"
                    disabled={uploadingEventId !== null}
                >
                    {uploadingEventId !== null ? (
                    <span className="spinner">⌛</span>
                    ) : (
                    editingEvent ? 'Update Event' : 'Add Event'
                    )}
                </button>
                {actionMessages[editingEvent?.id || 'new'] && (
                    <span className={`action-message ${actionMessages[editingEvent?.id || 'new'].type}`}>
                    {actionMessages[editingEvent?.id || 'new'].message}
                    </span>
                )}
                </div>
          </form>
  
          <div className="events-list">
            {liveEvents.map(event => (
              <div key={event.id} className="event-item">
                <h4>{event.title}</h4>
                <p>{event.description}</p>
                <div className="event-datetime">
                  <Calendar size={16} />
                  <span>
                    {new Date(event.event_date).toLocaleDateString()} at {' '}
                    {new Date(event.event_date).toLocaleTimeString()}
                  </span>
                </div>
                
                    {event.file_url && (
                    <div className="file-preview">
                        {event.file_type === 'image' ? (
                        <img src={event.file_url} alt={event.title} className="preview-image" />
                        ) : (
                        <div className="pdf-preview">
                            {event.thumbnail_url ? (
                            <div className="pdf-thumbnail-container">
                                <img 
                                src={event.thumbnail_url} 
                                alt={`${event.title} preview`} 
                                className="pdf-thumbnail" 
                                />
                                <a 
                                href={event.file_url} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="view-pdf-link"
                                >
                                View Details
                                </a>
                            </div>
                            ) : (
                            <div className="pdf-icon">PDF</div>
                            )}
                        </div>
                        )}
                    </div>
                    )}
  
                <div className="event-controls">
                  <button onClick={() => handleEditEvent(event)}>
                    <Edit size={16} />
                    {editingEvent?.id === event.id ? 'Cancel Edit' : 'Edit'}
                  </button>
                  <button onClick={() => handleDeleteEvent(event.id)}>
                    <Trash2 size={16} /> Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default EventsManagement;