import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import { validateEmail } from '../utils/validation';
import '../styles/SecurityModals.css';
import PropTypes from 'prop-types';

const EmailChange = ({ 
  currentEmail, 
  isModal = false, 
  isVendor = false, 
  isVerification = false,
  onClose, 
  onSuccess,
  vendorContext = ''
}) => {
  const [state, setState] = useState({
    loading: false,
    error: '',
    message: '',
    verificationComplete: false
  });
  const [newEmail, setNewEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const vendorSubUrl = searchParams.get('vendor');

  useEffect(() => {
    let mounted = true;
    let verificationAttempted = false;
    let timeoutId;
    
    const verify = async () => {
      if (isVerification && token && !verificationAttempted && mounted) {
        verificationAttempted = true;
        await handleVerification();
      }
    };
  
    verify();
    
    return () => {
      mounted = false;
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isVerification, token]);

  const handleVerification = async () => {
    // Prevent multiple verification attempts
    if (state.verificationComplete || state.loading) {
      return;
    }
  
    // Set loading and verificationComplete immediately to avoid repeated calls
    setState(prev => ({ 
      ...prev, 
      loading: true, 
      verificationComplete: true
    }));
  
    try {
      // Clear any existing auth headers
      delete axios.defaults.headers.common['Authorization'];
      
      console.log('Initiating verification for token:', token);
      const response = await axios.get(`/api/auth/verify-email/${token}`);
      
      console.log('Verification response:', response.data);
  
      // If verification is not successful, show a generic message but still redirect
      if (!response.data.success) {
        setState(prev => ({
          ...prev,
          verificationComplete: false,
          message: 'The verification link may have expired or is invalid.'
        }));
      }
  
      // Restore auth token if it exists
      const authToken = localStorage.getItem('token');
      if (authToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
      }
  
      // Redirect based on user role after a brief delay
      await new Promise(resolve => setTimeout(resolve, 1500));
      if (response.data.role === 'vendor') {
        navigate('/vendor/dashboard', { replace: true });
      } else if (vendorSubUrl) {
        navigate(`/vendor/${vendorSubUrl}`, { replace: true });
      } else {
        navigate('/', { replace: true });
      }
  
    } catch (error) {
      console.error('Verification error:', error);
      setState(prev => ({
        ...prev,
        verificationComplete: false,
        message: 'If successfully verified then you will be redirected. If not, you may have an expired link or already verified your new email. You can close this page and try again if needed.'
      }));
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };
  
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setState(prev => ({ ...prev, error: '' }));
  
    const validationErrors = validateEmail(newEmail);
    if (validationErrors.length > 0) {
      setState(prev => ({ ...prev, error: validationErrors.join('. ') }));
      return;
    }
  
    setState(prev => ({ ...prev, loading: true }));
  
    try {
      // Just use single endpoint for both vendors and customers
      const currentVendorSubUrl = window.location.pathname.split('/')[2];
  
      const response = await axios.post('/api/auth/change-email', {  // Changed to single endpoint
        newEmail,
        password: currentPassword,
        vendorSubUrl: isVendor ? undefined : currentVendorSubUrl
      });
  
      setState(prev => ({
        ...prev,
        message: 'Verification email sent to your new address. Please check your inbox.',
        error: ''
      }));
      
      if (onSuccess) {
        setTimeout(() => {
          onSuccess(response.data.message);
        }, 2000);
      }
    } catch (err) {
      console.error('Email change error:', err);
      setState(prev => ({
        ...prev,
        error: err.response?.data?.error || 'Error changing email address',
        message: ''
      }));
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  if (isVerification) {
    return (
      <div className="standalone-form">
        <div className="form-container verification-container">
          <h2>Email Verification</h2>
          {state.loading && (
            <div className="loading-state">
              <div className="spinner"></div>
              <p>Verifying your email address...</p>
            </div>
          )}
          
          {state.verificationComplete ? (
            <div className="success-message">
              {state.message}
              <div className="redirect-info">
                <p>You will be automatically redirected in a moment...</p>
              </div>
            </div>
          ) : state.message ? (
            <div className="generic-message">
              {state.message}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  

  return (
    <div className={isModal ? "security-modal-overlay" : "standalone-form"}>
      <div className={isModal ? "security-modal" : "form-container"}>
        <div className="modal-header">
          <h2>Change Email Address</h2>
          {vendorContext && (
            <p className="vendor-context">For {vendorContext}</p>
          )}
          {isModal && <button onClick={onClose} className="close-btn">&times;</button>}
        </div>

        <div className="current-info">
          <p>Current Email: <strong>{currentEmail}</strong></p>
        </div>

        {state.message ? (
          <div className="success-message">
            {state.message}
            <div className="verification-instructions">
              <p>Please check your new email address for verification instructions.</p>
              <p>The verification link will expire in 24 hours.</p>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="newEmail">New Email Address</label>
              <input
                type="email"
                id="newEmail"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                required
                disabled={state.loading}
              />
            </div>

            <div className="form-group">
              <label htmlFor="currentPassword">Current Password</label>
              <input
                type="password"
                id="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
                disabled={state.loading}
              />
            </div>

            {state.error && <div className="error-message">{state.error}</div>}

            <div className="modal-actions">
              <button 
                type="submit" 
                className="submit-btn" 
                disabled={state.loading}
              >
                {state.loading ? 'Processing...' : 'Change Email'}
              </button>
              {isModal && (
                <button 
                  type="button" 
                  className="cancel-btn" 
                  onClick={onClose}
                  disabled={state.loading}
                >
                  Cancel
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

EmailChange.propTypes = {
  currentEmail: PropTypes.string,
  isModal: PropTypes.bool,
  isVendor: PropTypes.bool,
  isVerification: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  vendorContext: PropTypes.string
};

EmailChange.defaultProps = {
  currentEmail: '',
  isModal: false,
  isVendor: false,
  isVerification: false,
  onClose: () => {},
  onSuccess: () => {},
  vendorContext: ''
};

export default EmailChange;