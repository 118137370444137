import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from '../../utils/axiosConfig';
import { setAuthToken } from '../../utils/auth';
import debounce from 'lodash.debounce';
import '../../styles/SpecialsAndMenus.css';

function SpecialsAndMenus() {
    const [specials, setSpecials] = useState([]);
    const [menus, setMenus] = useState([]);
    const [newSpecial, setNewSpecial] = useState({
      name: '',
      description: '',
      value: '',
      image: null,
      weblink: '',
    });
    const [newMenu, setNewMenu] = useState({
      pdfFile: null,
      weblink: '',
    });
    const [editingSpecial, setEditingSpecial] = useState(null);
    const [editingMenu, setEditingMenu] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [savingId, setSavingId] = useState(null);
    const [actionMessages, setActionMessages] = useState({});
    const specialsHeaderRef = useRef(null);

    const setActionMessage = useCallback((id, type, message) => {
      setActionMessages(prev => ({
        ...prev,
        [id]: { type, message }
      }));
      setTimeout(() => {
        setActionMessages(prev => {
          const newMessages = { ...prev };
          delete newMessages[id];
          return newMessages;
        });
      }, 3000);
    }, []);

    const fetchSpecials = useCallback(async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/api/vendors/specials?page=${page}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSpecials(prev => page === 1 ? response.data.specials : [...prev, ...response.data.specials]);
        setHasMore(response.data.hasMore);
      } catch (error) {
        console.error('Error fetching specials:', error);
        setActionMessage('fetch', 'error', 'Failed to fetch specials. Please try again.');
        setSpecials([]);
      }
    }, [page, setActionMessage]);
    
    const fetchMenus = useCallback(async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/vendors/menus', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setMenus(response.data);
      } catch (error) {
        console.error('Error fetching menus:', error);
        setActionMessage('fetch', 'error', 'Failed to fetch menus. Please try again.');
        setMenus([]);
      }
    }, [setActionMessage]);
											  
		const debouncedFetchData = useCallback(
      debounce(() => {
        setIsLoading(true);
        Promise.all([fetchSpecials(), fetchMenus()])
          .catch(error => console.error('Error fetching data:', error))
          .finally(() => setIsLoading(false));
      }, 300),
      [fetchSpecials, fetchMenus]
    );

  
    useEffect(() => {
      const token = localStorage.getItem('token');
      if (token) {
        setAuthToken(token);
      }
      debouncedFetchData();

      return () => {
        debouncedFetchData.cancel();
      };
    }, [debouncedFetchData]);

    const handleSpecialChange = (e) => {
      const { name, value, files } = e.target;
      setNewSpecial(prev => ({
        ...prev,
        [name]: files ? files[0] : value
      }));
    };

    const handleMenuChange = (e) => {
      const { name, value, files } = e.target;
      setNewMenu(prev => ({
        ...prev,
        [name]: files ? files[0] : value
      }));
    };

    const handleSpecialEdit = (special) => {
      if (editingSpecial?.id === special.id) {
        setEditingSpecial(null);
        setNewSpecial({
          name: '',
          description: '',
          value: '',
          image: null,
          weblink: '',
        });
      } else {
        setEditingSpecial(special);
        setNewSpecial({
          name: special.name,
          description: special.description || '',
          value: special.value || '',
          weblink: special.weblink || '',
          image: null
        });
        specialsHeaderRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    const handleMenuEdit = (menu) => {
      if (editingMenu?.id === menu.id) {
        setEditingMenu(null);
        setNewMenu({ pdfFile: null, weblink: '' });
      } else {
        setEditingMenu(menu);
        setNewMenu({
          pdfFile: null,
          weblink: menu.weblink || ''
        });
      }
    };

    const handleSpecialSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      Object.keys(newSpecial).forEach(key => {
        if (newSpecial[key]) {
          formData.append(key, newSpecial[key]);
        }
      });

      const specialId = editingSpecial?.id || 'new';
      setSavingId(specialId);

      try {
        if (editingSpecial) {
          await axios.put(`/api/vendors/specials/${editingSpecial.id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          setActionMessage(specialId, 'success', 'Special updated successfully');
        } else {
          await axios.post('/api/vendors/specials', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          setActionMessage(specialId, 'success', 'Special created successfully');
        }
        setNewSpecial({ name: '', description: '', value: '', image: null, weblink: '' });
        setEditingSpecial(null);
        setPage(1);
        fetchSpecials();
      } catch (error) {
        setActionMessage(specialId, 'error', error.response?.data?.error || 'Failed to save special');
      } finally {
        setSavingId(null);
      }
    };

    const handleMenuSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      if (newMenu.pdfFile) formData.append('pdfFile', newMenu.pdfFile);
      if (newMenu.weblink) formData.append('weblink', newMenu.weblink);

      const menuId = editingMenu?.id || 'new';
      setSavingId(menuId);

      try {
					 
        if (editingMenu) {
          await axios.put(`/api/vendors/menus/${editingMenu.id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          setActionMessage(menuId, 'success', 'Menu updated successfully');
        } else {
          await axios.post('/api/vendors/menus', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          setActionMessage(menuId, 'success', 'Menu created successfully');
        }
																							
        setNewMenu({ pdfFile: null, weblink: '' });
        setEditingMenu(null);
        fetchMenus();
      } catch (error) {
													   
        setActionMessage(menuId, 'error', error.response?.data?.error || 'Failed to save menu');
      } finally {
        setSavingId(null);
      }
    };

    const handleDeleteSpecial = async (id) => {
      if (!window.confirm('Are you sure you want to delete this special?')) return;
      
      setSavingId(id);
      try {
        await axios.delete(`/api/vendors/specials/${id}`);
        setActionMessage(id, 'success', 'Special deleted successfully');
        setPage(1);
        fetchSpecials();
      } catch (error) {
														
        setActionMessage(id, 'error', 'Failed to delete special');
      } finally {
        setSavingId(null);
      }
    };

    const handleDeleteMenu = async (id) => {
      if (!window.confirm('Are you sure you want to delete this menu?')) return;
      
      setSavingId(id);
      try {
        await axios.delete(`/api/vendors/menus/${id}`);
        setActionMessage(id, 'success', 'Menu deleted successfully');
        fetchMenus();
      } catch (error) {
													 
        setActionMessage(id, 'error', 'Failed to delete menu');
      } finally {
        setSavingId(null);
      }
    };

    const loadMoreSpecials = () => {
      setPage(prevPage => prevPage + 1);
    };

    if (isLoading) {
      return <div className="loader">Loading menus and specials...</div>;
    }

    return (
      <div className="specials-and-menus">
															   
																	 
		
        <section className="specials-section">
          <h2 ref={specialsHeaderRef}>Specials</h2>
          <form onSubmit={handleSpecialSubmit}>
            <input
              type="text"
              name="name"
              value={newSpecial.name}
              onChange={handleSpecialChange}
              placeholder="Special Name"
              required
            />
            <textarea
              name="description"
              value={newSpecial.description}
              onChange={handleSpecialChange}
              placeholder="Description"
            />
            <input
              type="number"
              name="value"
              value={newSpecial.value}
              onChange={handleSpecialChange}
              placeholder="Value in Rand"
            />
            <input
              type="file"
              name="image"
              onChange={handleSpecialChange}
              accept="image/*"
            />
            <input
              type="url"
              name="weblink"
              value={newSpecial.weblink}
              onChange={handleSpecialChange}
              placeholder="Weblink"
            />
            <div className="action-container">
              <button 
                type="submit" 
                className="submit-btn"
                disabled={savingId !== null}
              >
                {savingId === (editingSpecial?.id || 'new') ? (
                  <span className="spinner">⌛</span>
                ) : (
                  editingSpecial ? 'Update Special' : 'Add Special'
                )}
              </button>
              {actionMessages[editingSpecial?.id || 'new'] && (
                <span className={`action-message ${actionMessages[editingSpecial?.id || 'new'].type}`}>
                  {actionMessages[editingSpecial?.id || 'new'].message}
                </span>
              )}
            </div>
          </form>

          <div className="specials-list">
            {specials.map(special => (
              <div key={special.id} className="special-item">
                <h3>{special.name}</h3>
                <p>{special.description}</p>
                {special.value && <p>R{special.value}</p>}
                {special.image_url && <img src={special.image_url} alt={special.name} />}
                {special.weblink && <a href={special.weblink} target="_blank" rel="noopener noreferrer">More Info</a>}
                <div className="button-group">
                  <button 
                    onClick={() => handleSpecialEdit(special)}
                    disabled={savingId === special.id}
                  >
                    {editingSpecial?.id === special.id ? 'Cancel Edit' : 'Edit'}
                  </button>
                  <button 
                    onClick={() => handleDeleteSpecial(special.id)}
                    disabled={savingId === special.id}
                  >
                    {savingId === special.id ? '⌛' : 'Delete'}
                  </button>
                </div>
                {actionMessages[special.id] && (
                  <span className={`action-message ${actionMessages[special.id].type}`}>
                    {actionMessages[special.id].message}
                  </span>
                )}
              </div>
            ))}
          </div>
          {hasMore && (
            <button 
              onClick={loadMoreSpecials} 
              className="load-more-btn"
              disabled={savingId !== null}
            >
              Load More
            </button>
          )}
        </section>

        <section className="menus-section">
          <h2>Menus</h2>
          <form onSubmit={handleMenuSubmit}>
            <div className="menu-input-group">
              <label>
                Upload PDF Menu:
                <input
                  type="file"
                  name="pdfFile"
                  onChange={handleMenuChange}
                  accept="application/pdf"
                />
              </label>
            </div>
            <div className="menu-input-group">
              <label>
                OR Enter Menu Weblink:
                <input
                  type="url"
                  name="weblink"
                  value={newMenu.weblink}
                  onChange={handleMenuChange}
                  placeholder="https://example.com/menu"
                />
              </label>
            </div>
            <p className="menu-input-help">You can either upload a PDF file or provide a weblink to your menu.</p>
            <div className="action-container">
              <button 
                type="submit" 
                className="submit-btn"
                disabled={savingId !== null}
              >
                {savingId === (editingMenu?.id || 'new') ? (
                  <span className="spinner">⌛</span>
                ) : (
                  editingMenu ? 'Update Menu' : 'Upload Menu'
                )}
              </button>
              {actionMessages[editingMenu?.id || 'new'] && (
                <span className={`action-message ${actionMessages[editingMenu?.id || 'new'].type}`}>
                  {actionMessages[editingMenu?.id || 'new'].message}
                </span>
              )}
            </div>
          </form>

          <div className="menus-list">
            {menus.map(menu => (
              <div key={menu.id} className="menu-item">
                {menu.pdf_url && (
                  <>
                    <a href={menu.pdf_url} target="_blank" rel="noopener noreferrer" className="pdf-link">
                      View PDF Menu
                    </a>
                    {menu.thumbnail_url && (
                      <img src={menu.thumbnail_url} alt="Menu thumbnail" className="menu-thumbnail" />
                    )}
                  </>
                )}
                {menu.weblink && (
                  <a href={menu.weblink} target="_blank" rel="noopener noreferrer" className="weblink">
                    View Menu Online
                  </a>
                )}
                <div className="button-group">
                  <button 
                    onClick={() => handleMenuEdit(menu)}
                    disabled={savingId === menu.id}
                  >
                    {editingMenu?.id === menu.id ? 'Cancel Edit' : 'Edit'}
                  </button>
                  <button 
                    onClick={() => handleDeleteMenu(menu.id)}
                    disabled={savingId === menu.id}
                  >
                    {savingId === menu.id ? '⌛' : 'Delete'}
                  </button>
                </div>
                {actionMessages[menu.id] && (
                  <span className={`action-message ${actionMessages[menu.id].type}`}>
                    {actionMessages[menu.id].message}
                  </span>
                )}
              </div>
            ))}
          </div>
        </section>
      </div>
    );
}

export default SpecialsAndMenus;