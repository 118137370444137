import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import '../styles/AllVendors.css';

function AllVendorsPage() {
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    fetchVendors();
  }, []);

  const fetchVendors = async () => {
    try {
      const response = await axios.get('/api/vendors/all');
      setVendors(response.data);
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  return (
    <div className="all-vendors">
      <h1>All Vendor Loyalty Programs</h1>
      <div className="vendor-grid">
        {vendors.map(vendor => (
          <div key={vendor.id} className="vendor-card">
            <h2>{vendor.business_name}</h2>
            <Link to={`/vendor/${vendor.sub_url}`} className="view-programs-btn">View Programs</Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AllVendorsPage;