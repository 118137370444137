import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import { validatePassword } from '../utils/validation';
import '../styles/SecurityModals.css';
import PropTypes from 'prop-types';

const PasswordReset = ({ isModal = false, isVendor = false, isLoggedOut = false, onClose, onSuccess }) => {
  const [stage, setStage] = useState('request'); // request, reset, change
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const vendorSubUrl = searchParams.get('vendor');

  useEffect(() => {
    if (token) {
      setStage('reset');
    } else if (isModal && !isVendor && !isLoggedOut) {
      setStage('change');
    } else if (isLoggedOut) {
      setStage('request');
    }
  }, [token, isModal, isVendor, isLoggedOut]);

  const handleRequestReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const endpoint = isVendor ? '/api/auth/request-reset' : '/api/auth/request-reset';
      await axios.post(endpoint, { 
        email,
        vendorSubUrl: isModal ? window.location.pathname.split('/')[2] : vendorSubUrl
      });
      setMessage('If an account exists with this email, you will receive password reset instructions.');
      
      setTimeout(() => {
        if (onClose) onClose();
      }, 3000);
    } catch (err) {
      setError('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError('');

    const validationErrors = validatePassword(newPassword);
    if (validationErrors.length > 0) {
      setError(validationErrors.join('. '));
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true);

    try {
      const endpoint = isVendor 
        ? '/api/auth/change-password' 
        : '/api/auth/change-password';

      await axios.post(endpoint, {
        currentPassword,
        newPassword
      });

      setMessage('Password successfully updated');
      
      if (onSuccess) {
        setTimeout(() => {
          onSuccess('Password updated successfully');
        }, 2000);
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Error changing password');
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
  
    const validationErrors = validatePassword(newPassword);
    if (validationErrors.length > 0) {
      setError(validationErrors.join('. '));
      return;
    }
  
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
  
    setLoading(true);
  
    try {
      await axios.post('/api/auth/reset-password', {
        token,
        newPassword
      });
  
      setMessage('Password reset successful! Redirecting...');
      
      setTimeout(() => {
        if (vendorSubUrl) {
          // Add replace: true to prevent back button issues
          navigate(`/vendor/${vendorSubUrl}?fromReset=true`, { replace: true });
        } else {
          navigate('/login', { replace: true });
        }
      }, 3000);
    } catch (err) {
      console.error('Password reset error:', err); // Add for better debugging
      setError(err.response?.data?.error || 'Error resetting password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={isModal ? "security-modal-overlay" : "standalone-form"}>
      <div className={isModal ? "security-modal" : "form-container"}>
        {(!isModal || stage !== 'change') && (
          <div className="modal-header">
            <h2>{stage === 'change' ? 'Change Password' : 'Reset Password'}</h2>
            {vendorSubUrl && (
              <>
                <p className="vendor-context">
                  For {vendorSubUrl.charAt(0).toUpperCase() + vendorSubUrl.slice(1)}
                </p>
                {!isModal && ( // Only show back link on standalone form
                  <p className="back-link">
                    <a href={`/vendor/${vendorSubUrl}`}>
                      ← Back to {vendorSubUrl.charAt(0).toUpperCase() + vendorSubUrl.slice(1)}
                    </a>
                  </p>
                )}
              </>
            )}
            {isModal && <button onClick={onClose} className="close-btn">&times;</button>}
          </div>
        )}
  
        {/* Add better error handling with context */}
        {error && (
          <div className="error-message">
            {error}
            {error.includes('expired') && vendorSubUrl && (
              <p className="error-action">
                <a href={`/vendor/${vendorSubUrl}`}>
                  Return to vendor page and request a new reset link
                </a>
              </p>
            )}
          </div>
        )}

        {message ? (
          <div className="success-message">{message}</div>
        ) : (
          <form onSubmit={
            stage === 'request' 
              ? handleRequestReset 
              : stage === 'reset' 
                ? handleResetPassword 
                : handlePasswordChange
          }>
            {stage === 'request' && (
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={loading}
                />
              </div>
            )}

            {stage === 'change' && (
              <div className="form-group">
                <label htmlFor="currentPassword">Current Password</label>
                <input
                  type="password"
                  id="currentPassword"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                  disabled={loading}
                />
              </div>
            )}

            {(stage === 'reset' || stage === 'change') && (
              <>
                <div className="password-requirements">
                  <h4>Password Requirements:</h4>
                  <ul>
                    <li>At least 5 characters long</li>
                  </ul>
                </div>

                <div className="form-group">
                  <label htmlFor="newPassword">New Password</label>
                  <input
                    type="password"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    disabled={loading}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirm New Password</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    disabled={loading}
                  />
                </div>
              </>
            )}

            {error && <div className="error-message">{error}</div>}

            <div className="modal-actions">
              <button 
                type="submit" 
                className="submit-btn" 
                disabled={loading}
              >
                {loading ? 'Processing...' : stage === 'request' ? 'Send Reset Link' : 'Update Password'}
              </button>
              {isModal && (
                <button 
                  type="button" 
                  className="cancel-btn" 
                  onClick={onClose}
                  disabled={loading}
                >
                  Cancel
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

PasswordReset.propTypes = {
  isModal: PropTypes.bool,
  isVendor: PropTypes.bool,
  isLoggedOut: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  vendorContext: PropTypes.string // Add this if passing vendor name/context
};

PasswordReset.defaultProps = {
  isModal: false,
  isVendor: false,
  isLoggedOut: false,
  onClose: () => {},
  onSuccess: () => {},
  vendorContext: ''
}

export default PasswordReset;