import React, { useState, useEffect, useRef } from 'react';
import './InfoTooltip.css';

const InfoTooltip = ({ 
  text, 
  position = 'top',
  className = '',
  variant = 'default',
  children
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const triggerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target) &&
          triggerRef.current && !triggerRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setShowTooltip(!showTooltip);
    }
  };

  return (
    <span 
      className={`info-tooltip-container ${className}`}
      ref={triggerRef}
    >
      <button
        className={`info-icon ${variant}`}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={() => setShowTooltip(!showTooltip)}
        onKeyPress={handleKeyPress}
        aria-label="Information"
        tabIndex={0}
      >
        {children || <span className="info-circle">i</span>}
      </button>
      {showTooltip && (
        <div 
          className={`tooltip-content ${position}`}
          ref={tooltipRef}
          role="tooltip"
          aria-live="polite"
        >
          {text}
          <div className="tooltip-arrow" />
        </div>
      )}
    </span>
  );
};

export default InfoTooltip;