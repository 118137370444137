import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { registerPWA } from '../utils/auth';
import { validateForm } from '../utils/validation';
import '../styles/RegisterPWA.css';

function RegisterPWA() {
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    password: '',
    fullName: '',
    marketingConsent: false
  });
  const [errors, setErrors] = useState({});
  const { subUrl } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    
    setFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));

    // Clear error for the field being changed
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: []
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    const validationErrors = validateForm(formData, 'pwaRegistration');
    const hasErrors = Object.values(validationErrors).some(fieldErrors => fieldErrors.length > 0);
    
    if (hasErrors) {
      setErrors(validationErrors);
      setIsSubmitting(false);
      return;
    }
   
    try {
      const response = await registerPWA(
        formData.email,
        formData.phone,
        formData.password,
        formData.fullName,
        formData.marketingConsent,
        subUrl
      );
      localStorage.setItem('token', response.token);
      navigate(`/vendor/${subUrl}`);
    } catch (err) {
      setErrors({
        submit: [err.response?.data?.error || 'Registration failed']
      });
    } finally {
      setIsSubmitting(false);
    }
   };

  return (
    <div className="register-pwa">
      <h2>Register</h2>
      {errors.submit && (
        <div className="error-message">
          {errors.submit.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
          {errors.email?.length > 0 && (
            <div className="field-error">
              {errors.email[0]}
            </div>
          )}
        </div>

        <div className="form-group">
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone"
            required
          />
          {errors.phone?.length > 0 && (
            <div className="field-error">
              {errors.phone[0]}
            </div>
          )}
        </div>

        <div className="form-group">
        <div className="password-requirements">
          <small>Password must be at least 5 characters long</small>
        </div>
        <div className="password-input-group">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            required
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="password-toggle"
          >
            {showPassword ? "Hide" : "Show"}
          </button>
        </div>
        {errors.password?.length > 0 && (
          <div className="field-error">
            {errors.password[0]}
          </div>
        )}
      </div>

        <div className="form-group">
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="Full Name"
            required
          />
          {errors.fullName?.length > 0 && (
            <div className="field-error">
              {errors.fullName[0]}
            </div>
          )}
        </div>

        <label className="consent-label">
          <input
            type="checkbox"
            name="marketingConsent"
            checked={formData.marketingConsent}
            onChange={handleChange}
          />
          <span>I agree to receive marketing communications</span>
        </label>

        {/* <button type="submit">Register</button> */}
        <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Registering...' : 'Register'}
      </button>
      </form>

      <div className="back-link">
        <button onClick={() => navigate(-1)} className="back-button">← Back</button>
      </div>

    </div>
  );
}

export default RegisterPWA;