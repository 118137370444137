export const validatePassword = (password) => {
  const errors = [];
  if (!password) {
    errors.push('Password is required');
  }
  if (password.length < 5) {
    errors.push('Password must be at least 5 characters long');
  }
  return errors;
};

export const validatePhone = (phone) => {
  const errors = [];
  if (!phone) {
    errors.push('Phone number is required');
    return errors;
  }

  // Basic phone format validation (allows +, spaces, and dashes)
  const phoneRegex = /^\+?[\d\s-]{10,}$/;
  if (!phoneRegex.test(phone.replace(/\s+/g, ''))) {
    errors.push('Please enter a valid phone number');
  }

  return errors;
};

  
  export const validateEmail = (email) => {
    const errors = [];
  
    if (!email) {
      errors.push('Email is required');
      return errors;
    }
  
    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      errors.push('Please enter a valid email address');
    }
  
    // Additional checks for common mistakes
    if (email.includes('..')) {
      errors.push('Email cannot contain consecutive dots');
    }
  
    if (email.split('@').length > 2) {
      errors.push('Email cannot contain multiple @ symbols');
    }
  
    return errors;
  };
  
  // Real-time validation helper
  export const validateField = (fieldName, value, additionalData = {}) => {
    switch (fieldName) {
      case 'password':
        return validatePassword(value);
      
      case 'confirmPassword':
        const errors = [];
        if (value !== additionalData.password) {
          errors.push('Passwords do not match');
        }
        return errors;
      
      case 'email':
        return validateEmail(value);
      
      case 'newEmail':
        const emailErrors = validateEmail(value);
        if (value === additionalData.currentEmail) {
          emailErrors.push('New email must be different from current email');
        }
        return emailErrors;
  
      default:
        return [];
    }
  };
  
  // Form state validation helper
  export const validateForm = (formData, formType) => {
    const errors = {};
  
    switch (formType) {
      case 'passwordChange':
        errors.currentPassword = formData.currentPassword ? [] : ['Current password is required'];
        errors.newPassword = validatePassword(formData.newPassword);
        errors.confirmPassword = formData.newPassword === formData.confirmPassword 
          ? [] 
          : ['Passwords do not match'];
        break;
  
      case 'emailChange':
        errors.newEmail = validateEmail(formData.newEmail);
        errors.password = formData.password ? [] : ['Password is required'];
        if (formData.newEmail === formData.currentEmail) {
          errors.newEmail.push('New email must be different from current email');
        }
        break;
  
      case 'pwaRegistration':
        errors.email = validateEmail(formData.email);
        errors.password = validatePassword(formData.password);
        errors.phone = validatePhone(formData.phone);
        errors.fullName = !formData.fullName ? ['Full name is required'] : [];
        break;
  
      default:
        console.warn(`Unhandled form type: ${formType}`);
        break;
    }
  
    return errors;
  };