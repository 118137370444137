import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig';
import { setAuthToken } from '../utils/auth';
import '../styles/PushPermission.css';

function PushNotificationSubscription() {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    console.log('PushNotificationSubscription component mounted');
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
      console.log('Auth token set');
    }
    checkAndUpdateSubscription();
  }, []);

  const checkAndUpdateSubscription = async () => {
    console.log('Checking and updating subscription status...');
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      try {
        const registration = await navigator.serviceWorker.ready;
        let subscription = await registration.pushManager.getSubscription();

        if (subscription) {
          setIsSubscribed(true);
          await sendSubscriptionToServer(subscription);
        } else {
          setIsSubscribed(false);
          setShowPopup(true); // Show popup for initial subscription
        }

        // Set up periodic check
        setInterval(async () => {
          subscription = await registration.pushManager.getSubscription();
          if (subscription) {
            await sendSubscriptionToServer(subscription);
          } else {
            setIsSubscribed(false);
            setShowPopup(true); // Show popup for resubscription
          }
        }, 24 * 60 * 60 * 1000); // Check every 24 hours

      } catch (error) {
        console.error('Error checking subscription:', error);
        setError('Failed to check subscription status: ' + error.message);
      }
    } else {
      console.log('Push notifications are not supported in this browser');
      setError('Push notifications are not supported in this browser');
    }
  };

  const requestAndSubscribe = async () => {
    try {
      // This will trigger the browser's native permission request
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        await subscribeUser();
      } else {
        console.log('Notification permission not granted');
        setError('Please allow notifications to receive updates. You can enable them in your browser settings.');
      }
    } catch (error) {
      console.error('Error requesting permission:', error);
      setError('Failed to request notification permission: ' + error.message);
    } finally {
      setShowPopup(false);
    }
  };

  const sendSubscriptionToServer = async (subscription) => {
    try {
      await axios.post('/api/push-subscriptions/subscribe', { subscription });
      console.log('Subscription sent to server');
      setMessage('Subscription successfully sent to server');
    } catch (error) {
      console.error('Error sending subscription to server:', error);
      if (error.response && error.response.status === 410) {
        // Subscription is no longer valid
        setIsSubscribed(false);
        setError('Your subscription is no longer valid. Please resubscribe.');
        setShowPopup(true);
      } else {
        setError('Failed to send subscription to server: ' + error.message);
      }
    }
  };

  const subscribeUser = async () => {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY)
      });

      setIsSubscribed(true);
      await sendSubscriptionToServer(subscription);
      console.log('User is subscribed:', subscription);
      setMessage('Successfully subscribed to push notifications');
      setError(null);
    } catch (error) {
      console.error('Failed to subscribe the user:', error);
      if (error.name === 'NotAllowedError') {
        setError('Please enable notifications in your browser settings to receive updates');
      } else {
        setError('Failed to subscribe to push notifications: ' + error.message);
      }
    }
  };

  const unsubscribeUser = async () => {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();
      if (subscription) {
        await subscription.unsubscribe();
        await axios.post('/api/push-subscriptions/unsubscribe');
        setIsSubscribed(false);
        setMessage('Successfully unsubscribed from push notifications');
        console.log('User is unsubscribed');
      }
    } catch (error) {
      console.error('Error unsubscribing', error);
      setError('Failed to unsubscribe from push notifications: ' + error.message);
    }
  };

  // Helper function to convert base64 to Uint8Array
  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  return (
    <div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {message && <p style={{ color: 'green' }}>{message}</p>}
      {!isSubscribed ? (
        <button onClick={() => setShowPopup(true)}>Enable Push Notifications</button>
      ) : (
        <div>
          <p>You are subscribed to push notifications.</p>
          <button onClick={unsubscribeUser}>Unsubscribe from Push Notifications</button>
        </div>
      )}
      {showPopup && (
        <div className="notification-popup">
          <p>We would like to send you updates to stay in the know</p>
          <button onClick={requestAndSubscribe} className="pushb">Allow</button>
          <button onClick={() => setShowPopup(false)} className="pushb">Block</button>
        </div>
      )}
    </div>
  );
}

export default PushNotificationSubscription;