import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';
import '../../styles/Reporting.css';
import { validateEmail } from '../../utils/validation';
import ErrorBoundary from '../ErrorBoundary';
import { InfoTooltip } from '../Common/InfoTooltip';

// Separate error boundary for report sections
const ReportSection = ({ children }) => (
  <ErrorBoundary fallback={<div className="error-fallback">Unable to load report data</div>}>
    {children}
  </ErrorBoundary>
);

function ReportingTab() {

  const ITEMS_PER_PAGE = 10;
  // Separate state for different sections
  const [registrationData, setRegistrationData] = useState(null);
  const [checkinsData, setCheckinsData] = useState(null);
  
  // Separate loading states
  const [isLoadingSettings, setIsLoadingSettings] = useState(true);
  const [isLoadingRegistrations, setIsLoadingRegistrations] = useState(true);
  const [isLoadingCheckins, setIsLoadingCheckins] = useState(true);
  const [vendorStats, setVendorStats] = useState(null);
  const [isLoadingStats, setIsLoadingStats] = useState(true);
  const [statsError, setStatsError] = useState(null);

  // Separate error states
  const [settingsError, setSettingsError] = useState(null);
  const [registrationsError, setRegistrationsError] = useState(null);
  const [checkinsError, setCheckinsError] = useState(null);
  
  // Pagination states
  const [registrationPage, setRegistrationPage] = useState(1);
  const [checkinPage, setCheckinPage] = useState(1);
  
  // Settings state
  const [reportSettings, setReportSettings] = useState({
    enabled: false,
    emails: [],
    time: "06:00"
  });
  const [newEmail, setNewEmail] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [settingsMessage, setSettingsMessage] = useState('');

  // Fetch data on mount and page changes
  useEffect(() => {
    fetchRegistrations();
  }, [registrationPage]);

  useEffect(() => {
    fetchCheckins();
  }, [checkinPage]);

  useEffect(() => {
    fetchReportSettings();
  }, []);

  useEffect(() => {
    fetchVendorStats();
  }, []);

  const fetchReportSettings = async () => {
    setIsLoadingSettings(true);
    setSettingsError(null);
    try {
      const response = await axios.get('/api/vendors/reports/daily-settings');
      setReportSettings({
        enabled: response.data.daily_report_enabled || false,
        emails: response.data.daily_report_emails || [],
        time: response.data.daily_report_time || "06:00"
      });
    } catch (error) {
      console.error('Error fetching report settings:', error);
      setSettingsError('Failed to load report settings');
    } finally {
      setIsLoadingSettings(false);
    }
  };

  const fetchRegistrations = async () => {
    setIsLoadingRegistrations(true);
    setRegistrationsError(null);
    try {
      const response = await axios.get(`/api/vendors/reports/registrations?page=${registrationPage}`);
      setRegistrationData(response.data);
      
      // If no data and not on first page, go back to first page
      if ((!response.data?.registrations || response.data.registrations.length === 0) && registrationPage > 1) {
        setRegistrationPage(1);
      }
    } catch (error) {
      console.error('Error fetching registrations:', error);
      setRegistrationsError('Failed to load registration data');
    } finally {
      setIsLoadingRegistrations(false);
    }
  };

  const fetchCheckins = async () => {
    setIsLoadingCheckins(true);
    setCheckinsError(null);
    try {
      const response = await axios.get(`/api/vendors/reports/checkins?page=${checkinPage}`);
      setCheckinsData(response.data);
    } catch (error) {
      console.error('Error fetching checkins:', error);
      setCheckinsError('Failed to load check-in data');
    } finally {
      setIsLoadingCheckins(false);
    }
  };

  const fetchVendorStats = async () => {
    setIsLoadingStats(true);
    setStatsError(null);
    try {
      const response = await axios.get('/api/vendors/reports/stats');
      setVendorStats(response.data);
    } catch (error) {
      console.error('Error fetching vendor stats:', error);
      setStatsError('Failed to load vendor statistics');
    } finally {
      setIsLoadingStats(false);
    }
  };

  const saveReportSettings = async () => {
    setIsSaving(true);
    try {
      await axios.put('/api/vendors/reports/daily-settings', {
        enabled: reportSettings.enabled,
        emails: reportSettings.emails,
        time: reportSettings.time
      });
      setSettingsMessage('Settings saved successfully');
      setTimeout(() => setSettingsMessage(''), 3000);
    } catch (error) {
      setSettingsMessage('Error saving settings');
      console.error('Error saving report settings:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const addEmail = (e) => {
    e.preventDefault();
    const validationErrors = validateEmail(newEmail);
    
    if (validationErrors.length > 0) {
      setSettingsMessage(`Invalid email: ${validationErrors.join('. ')}`);
      return;
    }
    
    if (!reportSettings.emails.includes(newEmail)) {
      setReportSettings((prev) => ({
        ...prev,
        emails: [...prev.emails, newEmail],
      }));
      setNewEmail('');
      setSettingsMessage('Email added successfully');
    } else {
      setSettingsMessage('Email already added');
    }
    setTimeout(() => setSettingsMessage(''), 3000);
  };

  const removeEmail = (emailToRemove) => {
    setReportSettings(prev => ({
      ...prev,
      emails: prev.emails.filter(email => email !== emailToRemove)
    }));
  };

  // Render helper functions
  const renderSettingsSection = () => (
    <div className="report-settings-section">
      <h2>Daily Report Settings</h2>
      {isLoadingSettings ? (
        <div className="loading">Loading settings...</div>
      ) : settingsError ? (
        <div className="error-message">{settingsError}</div>
      ) : (
        <div className="settings-form">
          <div className="setting-group">
            <label className="switch">
              <input
                type="checkbox"
                checked={reportSettings.enabled}
                onChange={(e) => setReportSettings(prev => ({
                  ...prev,
                  enabled: e.target.checked
                }))}
              />
              <span className="slider round"></span>
            </label>
            <span>Enable Daily Reports</span>
          </div>

          <div className="setting-group">
            <label>Report Time: <InfoTooltip 
            text="Can only be set and sent once per day on the hour" 
            variant="vendor"
            position="right"
          /></label>
           
           <select
          value={reportSettings.time}
          onChange={(e) => setReportSettings(prev => ({
            ...prev,
            time: e.target.value
          }))}
        >
          {Array.from({ length: 24 }, (_, i) => {
            const hour = i.toString().padStart(2, '0');
            return <option key={hour} value={`${hour}:00`}>{hour}:00</option>;
          })}
        </select>

          </div>

          <div className="setting-group">
            <label>Report Recipients:</label>
            <div className="email-list">
              {reportSettings.emails?.length > 0 ? (
                reportSettings.emails.map(email => (
                  <div key={email} className="email-tag">
                    <span>{email}</span>
                    <button 
                      onClick={() => removeEmail(email)}
                      className="remove-email"
                      aria-label={`Remove ${email}`}
                    >
                      ×
                    </button>
                  </div>
                ))
              ) : (
                <p className="no-data">No recipients added</p>
              )}
            </div>
            <form onSubmit={addEmail} className="add-email-form">
              <input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Add email address"
                required
              />
              <button type="submit">Add</button>
            </form>
          </div>

          <button 
            onClick={saveReportSettings}
            disabled={isSaving || !reportSettings.emails?.length}
            className="save-settings-btn"
          >
            {isSaving ? 'Saving...' : 'Save Settings'}
          </button>
          
          {settingsMessage && (
            <div className={`settings-message ${settingsMessage.includes('Error') ? 'error' : 'success'}`}>
              {settingsMessage}
            </div>
          )}
        </div>
      )}
    </div>
  );


  const renderStatsSection = () => (
    <ReportSection>
      <div className="report-section stats-overview">
        <h2>Overall Statistics</h2>
        {isLoadingStats ? (
          <div className="loading">Loading statistics...</div>
        ) : statsError ? (
          <div className="error-message">{statsError}</div>
        ) : vendorStats && (
          <>
          <div className="stats-grid">
          <div className="stat-card">
            <h3>Total Registrations</h3>
            <p className="stat-number">{vendorStats.totals.total_registrations}</p>
          </div>
          <div className="stat-card">
            <h3>Total Check-ins</h3>
            <p className="stat-number">{vendorStats.totals.total_checkins}</p>
          </div>
          <div className="stat-card">
            <h3>Average Days Between Check-ins</h3>
            <p className="stat-number">
              {vendorStats.totals.avg_days_between_checkins || 'N/A'}
              {vendorStats.totals.avg_days_between_checkins && ' days'}
            </p>
          </div>
        </div>
  
            <h3>Top 5 Most Active Customers</h3>
            <table className="report-table">
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Total Check-ins</th>
                  <th>Cards Completed</th>
                  <th>Avg. Days Between Visits</th>
                </tr>
              </thead>
              <tbody>
                {vendorStats.topCustomers.map((customer, index) => (
                  <tr key={index}>
                    <td>{customer.full_name}</td>
                    <td>{customer.total_checkins}</td>
                    <td>{customer.total_cards_completed}</td>
                    <td>{customer.avg_days_between_visits}</td>
                  </tr>
                ))}
              </tbody>
            </table>
  
            <h3>Monthly Activity</h3>
            <table className="report-table">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Registrations</th>
                  <th>Check-ins</th>
                </tr>
              </thead>
              <tbody>
                {vendorStats.monthly.map((month, index) => (
                  <tr key={index}>
                    <td>{new Date(month.month).toLocaleDateString(undefined, { year: 'numeric', month: 'long' })}</td>
                    <td>{month.registrations}</td>
                    <td>{month.checkins}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </ReportSection>
  );


  const renderRegistrationsSection = () => (
    <ReportSection>
      <div className="report-section">
        <h2>Registration Report</h2>
        {isLoadingRegistrations ? (
          <div className="loading">Loading registrations...</div>
        ) : registrationsError ? (
          <div className="error-message">{registrationsError}</div>
        ) : (
          <>
            <div className="daily-summary">
              {registrationData?.dailySummary?.length > 0 ? (
                registrationData.dailySummary.map(day => (
                  <div key={day.registration_date} className="daily-stat">
                    <span>{new Date(day.registration_date).toLocaleDateString()}</span>
                    <span className="registration-count">
                      {day.registration_count} new registrations
                    </span>
                  </div>
                ))
              ) : (
                <div className="no-data">No registration data available</div>
              )}
            </div>
  
            <h3>Recent Registrations</h3>
            {!registrationData?.registrations?.length ? (
              <div className="no-data">No registrations found</div>
            ) : (
              <>
                <table className="report-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {registrationData.registrations.map(reg => (
                      <tr key={reg.id}>
                        <td>{reg.full_name}</td>
                        <td>{reg.email}</td>
                        <td>{reg.phone}</td>
                        <td>{new Date(reg.enrolled_date).toLocaleDateString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
  
                {registrationData.totalPages > 1 && registrationData.registrations.length > 0 && (
                  <div className="pagination">
                    {registrationPage > 1 && (
                      <button 
                        onClick={() => setRegistrationPage(p => p - 1)}
                      >
                        Previous
                      </button>
                    )}
                    <span>Page {registrationPage} of {registrationData.totalPages}</span>
                    {registrationPage < registrationData.totalPages && registrationData.registrations.length === ITEMS_PER_PAGE && (
                      <button 
                        onClick={() => setRegistrationPage(p => p + 1)}
                      >
                        Next
                      </button>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </ReportSection>
  );

  const renderCheckinsSection = () => (
    <ReportSection>
      <div className="report-section">
        <h2>Daily Check-ins</h2>
        {isLoadingCheckins ? (
          <div className="loading">Loading check-ins...</div>
        ) : checkinsError ? (
          <div className="error-message">{checkinsError}</div>
        ) : (
          <>
            <div className="daily-summary">
              {checkinsData?.dailySummary?.length > 0 ? (
                checkinsData.dailySummary.map(day => (
                  <div key={day.checkin_date} className="daily-stat">
                    <span>{new Date(day.checkin_date).toLocaleDateString()}</span>
                    <span className="checkin-count">{day.checkin_count} check-ins</span>
                  </div>
                ))
              ) : (
                <div className="no-data">No check-ins data available</div>
              )}
            </div>

            {checkinsData?.recentCheckins?.length > 0 && (
              <>
                <h3>Recent Check-ins</h3>
                <table className="report-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Program</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkinsData.recentCheckins.map((checkin, index) => (
                      <tr key={`${checkin.full_name}-${checkin.checkin_date}-${index}`}>
                        <td>{checkin.full_name}</td>
                        <td>{checkin.program_name}</td>
                        <td>{new Date(checkin.checkin_date).toLocaleDateString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {checkinsData.totalPages > 1 && checkinsData.recentCheckins.length > 0 && (
                  <div className="pagination">
                    {checkinPage > 1 && (
                      <button 
                        onClick={() => setCheckinPage(p => p - 1)}
                      >
                        Previous
                      </button>
                    )}
                    <span>Page {checkinPage} of {checkinsData.totalPages}</span>
                    {checkinPage < checkinsData.totalPages && checkinsData.recentCheckins.length === ITEMS_PER_PAGE && (
                      <button 
                        onClick={() => setCheckinPage(p => p + 1)}
                      >
                        Next
                      </button>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </ReportSection>
  );

  // Main render
  return (
    <div className="reporting-tab">
      <ErrorBoundary>
        {renderSettingsSection()}
        {renderStatsSection()}
        {renderRegistrationsSection()}
        {renderCheckinsSection()}
      </ErrorBoundary>
    </div>
  );
}

export default ReportingTab;