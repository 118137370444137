import React, { useState, useEffect, useRef, useCallback } from 'react';
import debounce from 'lodash.debounce';
import QrScanner from 'qr-scanner';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import { setAuthToken } from '../utils/auth';
import '../styles/LoyaltyCard.css';
import PinRedemptionModal from './PinRedemptionModal';
import { IOSPWAHandler } from '../utils/iosPwaHandler';

function LoyaltyCard({ program: propProgram }) {
  const [program, setProgram] = useState(propProgram || null);
  const { programId } = useParams();
  const [lastRedeemedStamp, setLastRedeemedStamp] = useState(null);
  const [showScanner, setShowScanner] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const videoRef = useRef(null);
  const [qrScanner, setQrScanner] = useState(null);

  const [showPinModal, setShowPinModal] = useState(false);
  const [showPinRedeemModal, setShowPinRedeemModal] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [vendorSettings, setVendorSettings] = useState(null);
  const [isLoadingVendorSettings, setIsLoadingVendorSettings] = useState(false);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const [isIOS, setIsIOS] = useState(false);
  const [iosPwaHandler, setIosPwaHandler] = useState(null);
  const [hasCameraPermission, setHasCameraPermission] = useState(false);
  const [showRetryButton, setShowRetryButton] = useState(false);

  const navigate = useNavigate();

  // Debounce program data fetch
  const fetchProgramData = useCallback(
    debounce(async () => {
      if (!programId) return;
      
      try {
        const response = await axios.get(`/api/customers/programs/${programId}`);
        console.log('Program data:', response.data); // Debug log
        setProgram(response.data);
      } catch (error) {
        console.error('Error fetching program data:', error);
      }
    }, 300),
    [programId]
  );

  // Debounce vendor settings fetch
  const fetchVendorSettings = useCallback(
    debounce(async (vendorId) => {
      if (!vendorId) {
        console.log('No vendor ID provided'); // Debug log
        return;
      }
      
      try {
        setIsLoadingVendorSettings(true);
        console.log('Fetching settings for vendor:', vendorId); // Debug log
        const response = await axios.get(`/api/vendors/${vendorId}/redemption-settings`);
        console.log('Received vendor settings:', response.data); // Debug log
        setVendorSettings(response.data);
      } catch (error) {
        console.error('Error fetching vendor settings:', error);
      } finally {
        setIsLoadingVendorSettings(false);
      }
    }, 300),
    []
  )

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
    }
    
    if (!propProgram && programId) {
      fetchProgramData();
    }
  
    // Remove this condition since we'll handle it in a separate useEffect
    // if (program?.vendor_id) {
    //   fetchVendorSettings(program.vendor_id);
    // }
  
    return () => {
      fetchProgramData.cancel();
      fetchVendorSettings.cancel();
      if (qrScanner) {
        qrScanner.stop();
      }
    };
  }, [propProgram, programId, fetchProgramData, fetchVendorSettings]);

  useEffect(() => {
    if (program?.vendor_id) {
      console.log('Found vendor ID:', program.vendor_id);
      fetchVendorSettings(program.vendor_id);
    } else {
      console.log('No vendor ID found in program data:', program); // Debug log
    }
  }, [program, fetchVendorSettings]);

  useEffect(() => {
    if (lastRedeemedStamp !== null) {
      setTimeout(() => setLastRedeemedStamp(null), 1000);
    }
  }, [lastRedeemedStamp]);

  
  useEffect(() => {
    if (showScanner && videoRef.current) {
      const initializeScanner = async () => {
        try {

          if (iosPwaHandler?.isIOS) {
            // Ensure manifest is updated with camera permissions
            await iosPwaHandler.updateManifest({
              additionalFeatures: ['camera'],
              permissions: ['camera']
            });
          }

          // Explicitly request camera permission first
          const stream = await navigator.mediaDevices.getUserMedia({ 
            video: { 
              facingMode: 'environment',
              width: { ideal: 1280 },
              height: { ideal: 720 }
            } 
          });
  
         if (isIOS) {
          localStorage.setItem('camera-permission-granted', 'true');
        }
        setHasCameraPermission(true);	  
  
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            
            // Create scanner only after successful stream
            const scanner = new QrScanner(
              videoRef.current,
              result => handleScan(result.data),
              {
                onDecodeError: (error) => {
                  console.error('QR code decode error:', error);
                },
                maxScansPerSecond: 1,
                highlightScanRegion: true,
                highlightCodeOutline: true,
                returnDetailedScanResult: true
              }
            );
            setQrScanner(scanner);
            scanner.start();
          }
        } catch (error) {
          console.error('Camera access error:', error);
          setMessage({
            text: isIOS ? 
              'Please grant camera access in your device settings and reload the page' : 
              'Unable to access camera. Please check permissions.',
            type: 'error'
          });
        }
      };
  
      initializeScanner();
    }
    
    // Cleanup function
    return () => {
      if (qrScanner) {
        qrScanner.stop();
        qrScanner.destroy();
      }
      // Clean up camera stream
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        videoRef.current.srcObject = null;
      }
    };
  }, [showScanner]);

  
// const handleScannerOpen = async () => {
//   // Check if we need to handle iOS permissions
//   if (iosPwaHandler?.isIOS && !localStorage.getItem('camera-permission-granted')) {
//     try {
//       await navigator.mediaDevices.getUserMedia({ video: true });
//       localStorage.setItem('camera-permission-granted', 'true');
//       setShowScanner(true);
//     } catch (error) {
//       if (error.name === 'NotAllowedError') {
//         setMessage({
//           text: 'Please grant camera access in iOS Settings and reload the page',
//           type: 'error'
//         });
//       }
//     }
//   } else {
//     setShowScanner(true);
//   }
// };

const handleScannerOpen = async () => {
  try {
    // Check if we need to handle iOS permissions
    if (iosPwaHandler?.isIOS && !localStorage.getItem('camera-permission-granted')) {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        localStorage.setItem('camera-permission-granted', 'true');
        setShowScanner(true);
        setShowRetryButton(false);
        setMessage(null);
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          setMessage({
            text: 'Please grant camera access in iOS Settings and reload the page',
            type: 'error'
          });
        }
      }
    } else {
      // Non-iOS flow
      await navigator.mediaDevices.getUserMedia({ 
        video: { 
          facingMode: 'environment',
          width: { ideal: 1280 },
          height: { ideal: 720 }
        } 
      });
      setShowScanner(true);
      setShowRetryButton(false);
      setMessage(null);
    }
  } catch (error) {
    console.error('Camera access error:', error);
    if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
      setMessage({
        text: 'Camera access is required for scanning QR codes. Allow permissons from settings',
        type: 'error'
      });
      setShowRetryButton(true);
    } else {
      setMessage({
        text: 'Error accessing camera: ' + error.message,
        type: 'error'
      });
    }
  }
};


  const handleScan = useCallback(async (data) => {
    if (data) {
      try {
        const qrData = JSON.parse(data);
        if (qrData.type === 'visit_checkin' && qrData.programId === parseInt(programId)) {
          setIsLoading(true);
          setShowScanner(false);

          const response = await axios.post('/api/customers/redeem-visit', { programId: qrData.programId });
          
          setMessage({
            text: response.data.message,
            type: 'success'
          });

          await fetchProgramData();
        } else {
          setMessage({
            text: 'Invalid QR code',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error processing QR code:', error);
        setMessage({
          text: error.response?.data?.error || 'Error processing QR code',
          type: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [programId, fetchProgramData]);

  useEffect(() => {
    let timer;
    if (message) {
      timer = setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [message]);
 

 // Initialize iOS handler
 useEffect(() => {
  if (program?.vendor_id) {
    const handler = new IOSPWAHandler(
      { 
        business_name: program.name,
        logo_url: program.logo_url, // If available
        id: program.vendor_id
      },
      window.location.pathname.split('/')[2] // Get subUrl from path
    );
    setIosPwaHandler(handler);
    setIsIOS(handler.isIOS);

    // Update manifest with camera permission and add iOS meta tags
    if (handler.isIOS) {
      handler.addIOSMetaTags(); // Add this line
      handler.updateManifest({
        additionalFeatures: ['camera'],
        permissions: ['camera']
      });
    }
  }
}, [program]);

// Add this effect for iOS cleanup
useEffect(() => {
  return () => {
    if (iosPwaHandler?.isIOS) {
      // Clean up any iOS-specific resources
      if (videoRef.current?.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach(track => track.stop());
      }
    }
  };
}, [iosPwaHandler]);

  // struggles to get alaert message and display data, need wait, refresh load if want to do this
  // const handleScan = async (data) => {
  //   if (data) {
  //     try {
  //       const qrData = JSON.parse(data);
  //       if (qrData.type === 'visit_checkin' && qrData.programId === parseInt(programId)) {
  //         if (qrScanner) {
  //           qrScanner.stop(); // Stop the scanner immediately after a successful scan
  //         }
  //         setShowScanner(false); // Close the scanner UI
  
  //         const response = await axios.post('/api/customers/redeem-visit', { programId: qrData.programId });
  //         if (response.data.error) {
  //           alert(response.data.error);
  //         } else {
  //           alert(response.data.message); // Show the success message
  //           await fetchProgramData(); // Update the program data
  //         }
  //       } else {
  //         alert('Invalid QR code');
  //       }
  //     } catch (error) {
  //       console.error('Error processing QR code:', error);
  //       alert('Error processing QR code: ' + (error.response?.data?.error || error.message));
  //     }
  //   }
  // };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleString();
  };

  const handleRedeemVoucher = async (voucherId, pin) => {
    try {
      setIsRedeeming(true);
      await axios.post('/api/customers/redeem-voucher', { 
        voucherId, 
        pin,
        programId: program.id 
      });
      
      fetchProgramData();
      setShowPinModal(false);
      setSelectedVoucher(null);
      alert('Voucher redeemed successfully');
    } catch (error) {
      console.error('Error redeeming voucher:', error);
      alert(error.response?.data?.error || 'Error redeeming voucher');
    } finally {
      setIsRedeeming(false);
    }
  };


  const handlePinRedeem = async (pin) => {
  try {
    setIsRedeeming(true);
    await axios.post('/api/customers/redeem-stamp-pin', {
      programId: program.id,
      pin
    });
    
    setShowPinRedeemModal(false);
    setMessage({
      text: 'Visit redeemed successfully',
      type: 'success'
    });
    await fetchProgramData();
  } catch (error) {
    setShowPinRedeemModal(false); // Close modal before showing error
    setMessage({
      text: error.response?.data?.error || 'Error redeeming visit',
      type: 'error'
    });
  } finally {
    setIsRedeeming(false);
  }
};

  const renderVoucherRedemptionButton = (voucher) => {
    if (voucher.redeemed) {
      console.log('Voucher is already redeemed');
      return null;
    }
    
    if (isLoadingVendorSettings) {
      console.log('Loading vendor settings...');
      return <div>Loading...</div>;
    }
    
    console.log('Vendor Settings:', vendorSettings);
    console.log('Customer Redemption Enabled:', vendorSettings?.customerRedemptionEnabled); 
    
    if (!vendorSettings?.customerRedemptionEnabled) {
      console.log('Customer redemption is not enabled');
      return null;
    }
  
    return (
      <button
        onClick={() => {
          setSelectedVoucher(voucher.id);
          setShowPinModal(true);
        }}
        className="redeem-voucher-btn"
      >
        Redeem Voucher
      </button>
    );
  };

  if (!program) return <div>Loading...</div>;

  return (
    <div className="loyalty-card-page">
      <div className="loyalty-card-header">
      <div className="header-actions">
        <button onClick={() => navigate(-1)} className="back-button">← Back</button>
        {vendorSettings?.customerRedemptionEnabled && (
          <button 
            onClick={() => setShowPinRedeemModal(true)}
            className="pin-redeem-button"
          >
            Redeem with PIN
          </button>
        )}
      </div>
    </div>
      
      {isLoading && (
        <div className="loading-overlay-card">
          <div className="loader-card"></div>
        </div>
      )}

        {message && (
          <div className={`message ${message.type}`}>
            {message.text}
            {showRetryButton && (
              <button 
                onClick={handleScannerOpen} 
                className="retry-button"
              >
                Try Again
              </button>
            )}
          </div>
        )}

      {showScanner && (
        <div className="qr-scanner-container">
          {!hasCameraPermission && (  // For both iOS and non-iOS
            <div className={`camera-fallback ${isIOS ? 'ios' : ''}`}>
              <p>Camera access is required for scanning.</p>
              {isIOS ? (
                <p>Please ensure camera permissions are granted in your device settings.</p>
              ) : null}
              {showRetryButton && (
                <button 
                  onClick={handleScannerOpen} 
                  className="retry-button"
                >
                  Try Again
                </button>
              )}
              <button 
                onClick={() => setShowScanner(false)} 
                className="close-scanner-btn"
              >
                Close
              </button>
            </div>
          )}
          <video 
            ref={videoRef} 
            style={{ 
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            playsInline
            autoPlay
            muted
          />
          {hasCameraPermission && (
            <button 
              onClick={() => {
                setShowScanner(false);
                if (qrScanner) {
                  qrScanner.stop();
                  const tracks = videoRef.current?.srcObject?.getTracks();
                  tracks?.forEach(track => track.stop());
                }
              }}
              className="close-scanner-btn"
            >
              Close Scanner
            </button>
          )}
        </div>
      )}
      <div className="loyalty-card">
        <h3 className="program-name">{program.name}</h3>
        <div className="stamps-container">
          {[...Array(parseInt(program.stamps_required))].map((_, index) => (
            <div
              key={index}
              className={`stamp ${index < program.stamps ? 'active' : ''} ${index === lastRedeemedStamp ? 'redeemed' : ''}`}
            >
              📍
            </div>
          ))}
        </div>
        <p className="stamp-count">Stamps: {program.stamps}/{program.stamps_required}</p>
        <p className="cards-completed">Cards Completed: {program.cards_completed || 0}</p>
        <button 
          onClick={handleScannerOpen} 
          id="capture-visit-button"
        >
          📸 Capture Visit
        </button>
        <button onClick={() => setShowAbout(!showAbout)} className="about-button">
          {showAbout ? 'Hide Details' : 'Show Details'}
        </button>
        {showAbout && (
          <div className="about-section">
            <p>{program.about || 'No additional information available.'}</p>
          </div>
        )}
        <h4 className="vouchers-title">Vouchers</h4>
        <div className="vouchers-container">
          {program.vouchers && program.vouchers.length > 0 ? (
            program.vouchers.map(voucher => (
              <div key={voucher.id} className={`voucher ${voucher.redeemed ? 'redeemed' : 'available'}`}>
                <div className="voucher-content">
                  <p className="voucher-description">{program.description || 'No description'}</p>
                  <p className="voucher-id">Voucher ID: {voucher.id}</p>
                  <p className="voucher-status">Status: {voucher.redeemed ? 'Redeemed' : 'Available'}</p>
                  {voucher.redeemed && <p className="voucher-redeemed-date">Redeemed at: {formatDate(voucher.redeemed_at)}</p>}
                  <p className="voucher-created-date">Created at: {formatDate(voucher.created_at)}</p>
                  {!voucher.redeemed && renderVoucherRedemptionButton(voucher)}
                </div>
              </div>
            ))
          ) : (
            <p className="no-vouchers">No vouchers available</p>
          )}
        </div>
      </div>
      {showPinModal && (
      <PinRedemptionModal
        isOpen={showPinModal}
        onClose={() => {
          setShowPinModal(false);
          setSelectedVoucher(null);
        }}
        onSubmit={(pin) => handleRedeemVoucher(selectedVoucher, pin)}
        isSubmitting={isRedeeming}  // Use isRedeeming instead of isLoadingVendorSettings
      />
    )}
    
    {showPinRedeemModal && (
      <PinRedemptionModal
        isOpen={showPinRedeemModal}
        onClose={() => setShowPinRedeemModal(false)}
        onSubmit={handlePinRedeem}
        isSubmitting={isRedeeming}
      />
    )}

    </div>
  );
}

export default LoyaltyCard;