import axios from '../utils/axiosConfig';

export const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const register = async (email, password, role, name) => {
  try {
    const res = await axios.post('/api/auth/register', { email, password, role, name });
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const login = async (email, password) => {
  try {
    const res = await axios.post('/api/auth/login', { email, password });
    localStorage.setItem('token', res.data.token);
    setAuthToken(res.data.token);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  setAuthToken(null);
  window.location.href = '/login';
};

export const isTokenValid = () => {
  const token = localStorage.getItem('token');
  if (!token) return false;

  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp * 1000 > Date.now();
  } catch (error) {
    return false;
  }
};

export const registerPWA = async (email, phone, password, fullName, marketingConsent, subUrl) => {
  try {
    const res = await axios.post('/api/auth/register-pwa', { 
      email, 
      phone, 
      password, 
      fullName, 
      marketingConsent, 
      subUrl 
    });
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};