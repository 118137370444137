import React, { useState } from 'react';
import '../styles/PinRedemptionModal.css';

const PinRedemptionModal = ({ isOpen, onClose, onSubmit, isSubmitting }) => {
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');
    if (!pin || pin.length < 4) {
      setError('Please enter a valid PIN');
      return;
    }
    onSubmit(pin);
  };
  
  if (!isOpen) return null;

  return (
    <div className="pin-modal-overlay" onClick={onClose}>
      <div className="pin-modal-content" onClick={e => e.stopPropagation()}>
        <h3>Enter Redemption PIN</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            placeholder="Enter PIN"
            maxLength={6}
            autoFocus
            required
          />
          {error && <p className="error-message">{error}</p>}
          <div className="pin-modal-buttons">
            <button 
              type="submit" 
              disabled={isSubmitting}
              className="submit-btn"
            >
              {isSubmitting ? 'Redeeming...' : 'Submit'}
            </button>
            <button 
              type="button" 
              onClick={onClose}
              className="cancel-btn"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PinRedemptionModal;