import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';

const ApproveBooking = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('loading');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const approveBooking = async () => {
      if (!token) {
        setStatus('error');
        setMessage('Invalid token');
        return;
      }

      try {
        const response = await axios.get(`/api/bookings/approve/${token}`);
        if (response.data.status === 'approved' || response.data.status === 'already_approved') {
          setStatus('success');
          setMessage(response.data.message);
        } else {
          setStatus('error');
          setMessage(response.data.message || 'An unexpected error occurred');
        }
      } catch (error) {
        setStatus('error');
        if (error.response && error.response.status === 400) {
          setMessage('This booking has already been approved. No further action is needed.');
        } else {
          setMessage(error.response?.data?.message || 'An error occurred while processing the booking');
        }
      }
    };

    approveBooking();
  }, [token]);

  return (
    <div className="approve-booking">
      <h2>Booking Approval</h2>
      {status === 'loading' && <p>Processing your approval...</p>}
      {status === 'success' && (
        <div>
          <p>{message}</p>
          <p>The booking has been successfully processed. No further action is needed.</p>
          <button onClick={() => navigate('/')}>Return to Home</button>
        </div>
      )}
      {status === 'error' && (
        <div>
          <p>Notice: {message}</p>
          <button onClick={() => navigate('/')}>Return to Home</button>
        </div>
      )}
    </div>
  );
};

export default ApproveBooking;