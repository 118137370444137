import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../utils/auth';
import '../styles/Auth.css';
import PasswordReset from './PasswordReset';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true); // Set loading to true when starting login

    try {
      const res = await login(email, password);
      // login function already sets the token in localStorage and axios headers
      
      // Add a small delay to ensure token is set
      await new Promise(resolve => setTimeout(resolve, 100));
  
      // Then navigate based on role
      if (res.user.role === 'customer') {
        navigate('/customer');
      } else if (res.user.role === 'vendor') {
        navigate('/vendor/dashboard');
      } else if (res.user.role === 'admin') {
        navigate('/admin/dashboard');
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Login failed');
    } finally {
      setLoading(false); // Set loading back to false regardless of outcome
    }
  };

  return (
    <div className="auth-container">
      <h1>Login</h1>
      {error && <p style={{color: 'red'}}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="email-input-group">
          <input 
            type="email" 
            placeholder="Email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required
            disabled={loading}
          />
        </div>
        <div className="password-input-group">
          <input 
            type={showPassword ? "text" : "password"}
            placeholder="Password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required
            disabled={loading}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="password-toggle"
            disabled={loading}
          >
            {showPassword ? "Hide" : "Show"}
          </button>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
        <div className="forgot-password">
          <button 
            type="button"
            onClick={() => setShowPasswordReset(true)}
            className="forgot-password-link"
            disabled={loading}
          >
            Forgot Password?
          </button>
        </div>								 
      </form>

      {showPasswordReset && (
        <PasswordReset
          isModal={true}
          isLoggedOut={true}
          onClose={() => setShowPasswordReset(false)}
          onSuccess={() => setShowPasswordReset(false)}
        />
      )}	
    </div>
  );
}

export default Login;