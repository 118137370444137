import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import axios from '../../utils/axiosConfig';
import { setAuthToken } from '../../utils/auth';

function CustomerProfile({ customerId, onClose }) {
    const [customerData, setCustomerData] = useState(null);
    const [newVoucher, setNewVoucher] = useState({ description: '', value: '' });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCustomerProfile = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`/api/vendors/customer-profile/${customerId}`);
            setCustomerData(response.data);
            setError(null);
        } catch (error) {
            console.error('Error fetching customer profile:', error);
            setError('Error fetching customer profile');
        } finally {
            setIsLoading(false);
        }
    };

    const debouncedFetchCustomerProfile = useCallback(
        debounce(() => {
            const token = localStorage.getItem('token');
            if (token) {
                setAuthToken(token);
            }
            fetchCustomerProfile();
        }, 300),
        [customerId]
    );

    useEffect(() => {
        debouncedFetchCustomerProfile();
        return () => {
            debouncedFetchCustomerProfile.cancel();
        };
    }, [debouncedFetchCustomerProfile]);

    const handleRedeemStamp = async (programId) => {
        try {
            await axios.post('/api/vendors/redeem-stamp', { customerId, programId });
            fetchCustomerProfile();
        } catch (error) {
            console.error('Error redeeming stamp:', error);
        }
    };

    const handleRedeemVoucher = async (voucherId) => {
        try {
          const response = await axios.post('/api/vendors/redeem-voucher', { customerId, voucherId });
          alert(response.data.message); // Or use a more user-friendly notification method
          fetchCustomerProfile();
        } catch (error) {
          console.error('Error redeeming voucher:', error);
          alert(error.response?.data?.error || 'Error redeeming voucher. Please try again.');
        }
      };

    const handleGenerateVoucher = async () => {
        if (!newVoucher.description && !newVoucher.value) {
            alert('Please provide either a description or a value for the voucher.');
            return;
        }

        try {
            await axios.post('/api/vendors/generate-voucher', { 
                customerId, 
                description: newVoucher.description || null, 
                value: newVoucher.value || null
            });
            setNewVoucher({ description: '', value: '' });
            fetchCustomerProfile();
        } catch (error) {
            console.error('Error generating voucher:', error);
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!customerData) return <div>No customer data available</div>;

    return (
        <div className="customer-profile">
            <h3>{customerData.full_name}</h3>
            <p>Phone: {customerData.phone}</p>
            <p>Loyalty Number: {customerData.loyalty_number}</p>
            
            <h4>Enrolled Programs</h4>
            {customerData.enrollments && customerData.enrollments.length > 0 ? (
                customerData.enrollments.map(enrollment => (
                    <div key={enrollment.program_id}>
                        <p>{enrollment.program_name}: {enrollment.stamps}/{enrollment.stamps_required}</p>
                        <button onClick={() => handleRedeemStamp(enrollment.program_id)}>Redeem Stamp</button>
                    </div>
                ))
            ) : (
                <p>No enrolled programs</p>
            )}

            <h4>Vouchers</h4>
            {customerData.vouchers && customerData.vouchers.length > 0 ? (
                customerData.vouchers.map(voucher => (
                    <div key={voucher.id}>
                        <p>{voucher.program_name} - {voucher.description || `Value: ${voucher.value}`}</p>
                        {!voucher.redeemed && (
                            <button onClick={() => handleRedeemVoucher(voucher.id)}>Redeem Voucher</button>
                        )}
                    </div>
                ))
            ) : (
                <p>No vouchers available</p>
            )}

            <h4>Generate Standalone Voucher</h4>
            <input
                type="text"
                placeholder="Description (e.g., Free glass of wine)"
                value={newVoucher.description}
                onChange={(e) => setNewVoucher({...newVoucher, description: e.target.value})}
            />
            <input
                type="number"
                placeholder="Value in Rand (optional)"
                value={newVoucher.value}
                onChange={(e) => setNewVoucher({...newVoucher, value: e.target.value})}
            />
            <button onClick={handleGenerateVoucher}>Generate Voucher</button>

            <button onClick={onClose}>Close Profile</button>
        </div>
    );
}

export default CustomerProfile;