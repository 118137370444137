import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import { setAuthToken } from '../utils/auth';
import '../styles/LoginModal.css';

function LoginModal({ onClose, onLoginSuccess, vendorSubUrl, onForgotPassword }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await axios.post('/api/auth/login', { email, password });
      setAuthToken(response.data.token);
      localStorage.setItem('token', response.data.token);
      
      if (response.data.user.role === 'customer') {
        onLoginSuccess(response.data.user);
      } else if (response.data.user.role === 'vendor') {
        navigate('/vendor/dashboard');
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Invalid credentials');
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = () => {
    // First close the modal
    onClose();
    
    // Use setTimeout to ensure modal is unmounted before navigation
    setTimeout(() => {
      navigate(`/register/${vendorSubUrl}`);
    }, 0);
  };

  return (
    <div className="login-modal-wrapper">
      <div className="modal-overlay">
        <div className="modal-dialog">
          <button onClick={onClose} className="modal-close">&times;</button>
          
          <div className="modal-header">
            <h2>Login</h2>
          </div>

          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="login-form-group">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={loading}
                  className="login-input"
                />
              </div>

              <div className="login-form-group password-input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  disabled={loading}
                  className="login-input"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="password-toggle"
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>

              {error && <div className="login-error-message">{error}</div>}

			 

              <button type="submit" className="modal-button" disabled={loading}>
                {loading ? 'Logging in...' : 'Login'}
              </button>
            </form>
          </div>

          <div className="forgot-password">
            <button 
              onClick={onForgotPassword}
              className="modal-link"
              disabled={loading}
            >
              Forgot Password?
            </button>
          </div>

          <div className="modal-footer">
            <div className="register-section">
              <span className="register-text">Don't have an account?</span>
              <button 
                onClick={handleRegister}
                className="modal-link"
                disabled={loading}
              >
                Register here
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;