import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';
import { logout, setAuthToken, isTokenValid } from '../utils/auth';
import '../styles/CustomerDashboard.css';

function CustomerDashboard() {
  const [enrolledPrograms, setEnrolledPrograms] = useState([]);
  const [standaloneVouchers, setStandaloneVouchers] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && isTokenValid()) {
      setAuthToken(token);
      setIsLoggedIn(true);
      fetchEnrolledPrograms();
    } else {
      localStorage.removeItem('token');
      setIsLoggedIn(false);
    }
  }, []);

  const fetchEnrolledPrograms = async () => {
    try {
      const response = await axios.get('/api/customers/programs');
      console.log('API response:', response.data);
      if (response.data && response.data.enrolledPrograms) {
        setEnrolledPrograms(response.data.enrolledPrograms);
      } else {
        setEnrolledPrograms([]);
      }
      if (response.data && response.data.standaloneVouchers) {
        setStandaloneVouchers(response.data.standaloneVouchers);
      } else {
        setStandaloneVouchers([]);
      }
    } catch (error) {
      console.error('Error fetching enrolled programs:', error);
      if (error.response && error.response.status === 401) {
        // Token is invalid or expired
        localStorage.removeItem('token');
        setIsLoggedIn(false);
      }
      setEnrolledPrograms([]);
      setStandaloneVouchers([]);
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="customer-dashboard">
        <h1>Customer Dashboard</h1>
        <p>Please log in to view your dashboard.</p>
      </div>
    );
  }


  return (
    <div className="customer-dashboard">
      <h1>Customer Dashboard</h1>
      <button className="logout-btn" onClick={logout}>Logout</button>
      <h2>Your Enrolled Loyalty Programs</h2>
      {enrolledPrograms.length === 0 ? (
        <p className="no-programs">You are not enrolled in any programs yet.</p>
      ) : (
        <div className="program-list">
          {enrolledPrograms.map(program => (
            <div key={program.id} className="program-card">
              <h3>{program.name}</h3>
              <p>Stamps: {program.stamps || 0}/{program.stamps_required}</p>
              <p>Vendor: {program.vendor_name}</p>
            </div>
          ))}
        </div>
      )}
      <h2>Your Standalone Vouchers</h2>
      {standaloneVouchers.length === 0 ? (
        <p className="no-vouchers">You don't have any standalone vouchers.</p>
      ) : (
        <div className="voucher-list">
          {standaloneVouchers.map(voucher => (
            <div key={voucher.id} className="voucher-card">
              <h3>{voucher.description || `Value: R${voucher.value}`}</h3>
              <p>Status: {voucher.redeemed_at ? 'Redeemed' : 'Available'}</p>
              <p>Vendor: {voucher.vendor_name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomerDashboard;