import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import axios from '../../utils/axiosConfig';
import { setAuthToken } from '../../utils/auth';


function CustomerSearch({ onSelectCustomer }) {
    const [query, setQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const debouncedSearch = useCallback(
        debounce(async (searchQuery) => {
          if (searchQuery.length < 2) return;
          setIsLoading(true);
          setError(null);
          const token = localStorage.getItem('token');
          if (token) {
            setAuthToken(token);
          }
          try {
            const response = await axios.get(`/api/vendors/search-customers?query=${searchQuery}`);
            console.log('Search response:', response.data);
            setSearchResults(response.data);
            if (response.data.length === 0) {
              setError('No customers found');
            }
          } catch (error) {
            console.error('Error searching customers:', error.response?.data || error.message);
            setError('Error searching customers');
            setSearchResults([]);
          } finally {
            setIsLoading(false);
          }
        }, 300),
        []
      );
    
      useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
          setAuthToken(token);
        }
      }, []);
    
      const handleInputChange = (e) => {
        const value = e.target.value;
        setQuery(value);
        debouncedSearch(value);
      };

      return (
        <div className="customer-search">
          <input
            type="text"
            value={query}
            onChange={handleInputChange}
            placeholder="Search by name, phone, or loyalty number"
          />
          {isLoading && <p>Loading...</p>}
          {error && <p className="error-message">{error}</p>}
          <div className="search-results">
            {searchResults.map(customer => (
              <div key={customer.id} className="customer-result" onClick={() => onSelectCustomer(customer)}>
                <p>{customer.full_name}</p>
                <p>Phone: {customer.phone}</p>
                <p>Loyalty Number: {customer.loyalty_number}</p>
              </div>
            ))}
          </div>
        </div>
      );
    }
    
    export default CustomerSearch;