import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';

const RedemptionSettings = ({ authToken }) => {
  const [settings, setSettings] = useState({
    customerRedemptionEnabled: false,
    customerRedemptionPin: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get('/api/vendors/redemption-settings');
        setSettings({
          customerRedemptionEnabled: response.data.customerRedemptionEnabled,
          customerRedemptionPin: response.data.customerRedemptionPin || ''
        });
        setError(''); // Clear any previous errors
      } catch (err) {
        console.error('Error fetching redemption settings:', err);
        setError(err.response?.data?.error || 'Error fetching settings');
        setSettings({
          customerRedemptionEnabled: false,
          customerRedemptionPin: ''
        });
      }
    };
  
    fetchSettings();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      // Use PUT instead of POST
      await axios.put('/api/vendors/redemption-settings', {
        customerRedemptionEnabled: settings.customerRedemptionEnabled,
        customerRedemptionPin: settings.customerRedemptionPin
      });
      
      setSuccess('Redemption settings updated successfully');
    } catch (err) {
      setError('Error updating settings: ' + (err.response?.data?.error || err.message));
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <div className="redemption-settings">
      <h3>Voucher & Visit Redemption Settings</h3>
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
      
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            <input 
              type="checkbox" 
              name="customerRedemptionEnabled"
              checked={settings.customerRedemptionEnabled} 
              onChange={handleInputChange}
            />
            Allow customer front admin PIN for voucher and visit redemption
          </label>
        </div>
        
        {settings.customerRedemptionEnabled && (
          <div className="form-group">
            <label>Redemption PIN:</label>
            <input 
              type="password" 
              name="customerRedemptionPin"
              value={settings.customerRedemptionPin} 
              onChange={handleInputChange}
              maxLength={6} 
              minLength={4}
              required={settings.customerRedemptionEnabled}
              placeholder="Enter 4-6 digit PIN"
            />
            <small>PIN must be 4-6 digits</small>
          </div>
        )}
        
        <button 
          type="submit" 
          className="btn btn-primary"
          disabled={settings.customerRedemptionEnabled && (!settings.customerRedemptionPin || settings.customerRedemptionPin.length < 4)}
        >
          Save Settings
        </button>
      </form>
    </div>
  );
};

export default RedemptionSettings;