import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import LoyaltyCard from './LoyaltyCard';
import { setAuthToken } from '../utils/auth';

function ProgramLoyaltyCard() {
  const [program, setProgram] = useState(null);
  const { subUrl, programId } = useParams();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
    }
    fetchProgramDetails();
  }, [subUrl, programId]);

  const fetchProgramDetails = async () => {
    try {
      const response = await axios.get(`/api/customers/programs/${programId}`);
      setProgram(response.data);
    } catch (error) {
      console.error('Error fetching program details:', error);
    }
  };

  if (!program) return <div>Loading...</div>;

  return (
    <div>
      <h1>{program.vendor_name}</h1>
      <h2>{program.name} Loyalty Card</h2>
      <LoyaltyCard program={program} />
    </div>
  );
}

export default ProgramLoyaltyCard;