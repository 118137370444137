import React, { useState } from 'react';
import '../styles/AboutModal.css';

function AboutModal({ program, onClose, onUpdate }) {
  const [about, setAbout] = useState(program.about || '');
  const [isEditing, setIsEditing] = useState(false);

  const handleUpdate = () => {
    onUpdate(about);
    setIsEditing(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>About {program.name}</h2>
        {isEditing ? (
          <>
            <textarea
              value={about}
              onChange={(e) => setAbout(e.target.value)}
              maxLength={1000}
            />
            <p>{about.length}/1000 characters</p>
            <button className="btn btn-small" onClick={handleUpdate}>Update</button>
            <button className="btn btn-small" onClick={() => setIsEditing(false)}>Cancel</button>
          </>
        ) : (
          <>
            <p>{about || 'No additional information available.'}</p>
            <button className="btn btn-small" onClick={() => setIsEditing(true)}>Edit</button>
          </>
        )}
        <button className="btn btn-small" onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default AboutModal;