import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';
import LoyaltyCard from './LoyaltyCard';
import { setAuthToken } from '../utils/auth';

function CustomerLoyaltyCards() {
  const [enrolledPrograms, setEnrolledPrograms] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
    }
    fetchEnrolledPrograms();
  }, []);

  const fetchEnrolledPrograms = async () => {
    try {
      const response = await axios.get('/api/customers/programs');
      setEnrolledPrograms(response.data);
    } catch (error) {
      console.error('Error fetching enrolled programs:', error);
    }
  };

  return (
    <div>
      <h1>My Loyalty Cards</h1>
      {enrolledPrograms.map(program => (
        <div key={program.id}>
          <h2>{program.vendor_name}</h2>
          <LoyaltyCard program={program} />
        </div>
      ))}
    </div>
  );
}

export default CustomerLoyaltyCards;