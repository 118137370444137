import React, { useState, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';
import axios from '../utils/axiosConfig';
import 'react-quill/dist/quill.snow.css';
import '../styles/EmailCampaignModal.css';
import imageCompression from 'browser-image-compression';

function EmailCampaignModal({ isOpen, onClose, onSubmit }) {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const quillRef = useRef(null);

  const MAX_FILE_SIZE_MB = 5; // Maximum file size in MB
  const MAX_WIDTH = 800; // Maximum width for images

  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
          alert(`Image is too large. Maximum size is ${MAX_FILE_SIZE_MB}MB.`);
          return;
        }

        try {
          const compressedFile = await imageCompression(file, {
            maxSizeMB: MAX_FILE_SIZE_MB,
            maxWidthOrHeight: MAX_WIDTH,
          });

          const formData = new FormData();
          formData.append('image', compressedFile);

          const response = await axios.post('/api/vendors/upload-image', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          });
          const imageUrl = response.data.url;

          const quill = quillRef.current.getEditor();
          const range = quill.getSelection(true);
          
          // Insert image with inline styles and alt text
          quill.insertEmbed(range.index, 'image', imageUrl);
          
          // Get the inserted image and add attributes
          const img = quill.root.querySelector(`img[src="${imageUrl}"]`);
          if (img) {
            img.alt = 'Email campaign image';
            img.style.maxWidth = '100%';
            img.style.height = 'auto';
            img.style.display = 'block';
            img.style.margin = '0 auto';
          }
        } catch (error) {
          console.error('Error uploading image:', error);
          alert('Failed to upload image. Please try again.');
        }
      }
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['link', 'image'],
        ['clean']
      ],
      handlers: {
        image: imageHandler
      }
    }
  }), []);

  if (!isOpen) return null;

  const handleSubmit = () => {
    // Process content to ensure all images have proper attributes
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    const images = tempDiv.querySelectorAll('img');
    images.forEach(img => {
      if (!img.alt) img.alt = 'Email campaign image';
      img.style.maxWidth = '100%';
      img.style.height = 'auto';
      img.style.display = 'block';
      img.style.margin = '0 auto';
    });
    const processedContent = tempDiv.innerHTML;

    onSubmit(processedContent, subject);
  };

  return (
    <div className="email-modal-overlay">
      <div className="email-modal">
        <h2>Create Email Campaign</h2>
        <input
          type="text"
          placeholder="Email Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="email-subject-input"
        />
        <div className="email-editor-container">
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={content}
            onChange={setContent}
            modules={modules}
            className="quill-editor"
          />
        </div>
        <div className="email-modal-buttons">
          <button onClick={handleSubmit} className="send-btn">Send</button>
          <button onClick={onClose} className="cancel-email-btn">Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default EmailCampaignModal;