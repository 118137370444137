import React, { useState } from 'react';
import EmailChange from './EmailChange';
import PasswordReset from './PasswordReset';
import '../styles/CustomerProfileEdit.css';

const CustomerProfileEdit = ({ customerProfile, onClose, onUpdate }) => {
  const [activeSection, setActiveSection] = useState(null);
  const [message, setMessage] = useState('');

  const handleSuccess = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      if (onUpdate) onUpdate();
    }, 2000);
  };

  return (
    <div className="profile-edit-container">
      <div className="profile-edit-header">
        <h2>Profile Settings</h2>
        <button onClick={onClose} className="close-btn">&times;</button>
      </div>

      {message ? (
        <div className="success-message">{message}</div>
      ) : (
        <>
          <div className="current-info">
            <h3>Current Information</h3>
            <div className="info-grid">
              <div className="info-item">
                <label>Name:</label>
                <span>{customerProfile.full_name}</span>
              </div>
              <div className="info-item">
                <label>Email:</label>
                <span>{customerProfile.email}</span>
              </div>
              <div className="info-item">
                <label>Loyalty Number:</label>
                <span>{customerProfile.loyalty_number}</span>
              </div>
            </div>
          </div>

          {!activeSection ? (
            <div className="edit-options">
              <button 
                className="edit-btn"
                onClick={() => setActiveSection('email')}
              >
                Change Email Address
              </button>
              <button 
                className="edit-btn"
                onClick={() => setActiveSection('password')}
              >
                Change Password
              </button>
            </div>
          ) : (
            <div className="edit-section">
              {activeSection === 'email' && (
                <EmailChange 
                  currentEmail={customerProfile.email}
                  isModal={true}
                  onClose={() => setActiveSection(null)}
                  onSuccess={(msg) => handleSuccess(msg)}
                />
              )}
              {activeSection === 'password' && (
                <PasswordReset 
                  isModal={true}
                  onClose={() => setActiveSection(null)}
                  onSuccess={(msg) => handleSuccess(msg)}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CustomerProfileEdit;