import axios from 'axios';

const instance = axios.create();

instance.interceptors.request.use((config) => {
  // For local development, use the proxy
  if (!process.env.REACT_APP_API_URL) {
    return config;
  }
  
  // For staging/production, use the full URL
  config.baseURL = process.env.REACT_APP_API_URL;
  return config;
});

export default instance;