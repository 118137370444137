import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';
import { setAuthToken, logout } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import '../styles/AdminDashboard.css';

function AdminDashboard() {
  const [activeTab, setActiveTab] = useState('overview');
  const navigate = useNavigate();
  const [metrics, setMetrics] = useState(null);
  const [recentLogs, setRecentLogs] = useState([]);
//   const [activeSessions, setActiveSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshInterval, setRefreshInterval] = useState(30000); // 30 seconds

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuthToken(null);
    navigate('/login');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
    }
  
    const fetchData = async () => {
      try {
        const [metricsRes, activeUsersRes, systemStatusRes, logsRes] = await Promise.all([
          axios.get('/api/admin/monitoring/metrics'),
          axios.get('/api/admin/monitoring/active-users'),
          axios.get('/api/admin/monitoring/system-status'),
          axios.get('/api/admin/logs')
        ]);
  
        setMetrics({
          securityMetrics: {
            total_logins: metricsRes.data.total_logins,
            failed_logins: metricsRes.data.failed_logins,
            password_resets: metricsRes.data.password_resets,
            email_changes: metricsRes.data.email_changes,
            currentPeriod: metricsRes.data.currentPeriod
          },
          activeUsers: activeUsersRes.data.activeUsers,
          activeSessions: activeUsersRes.data.sessions,
          systemHealth: systemStatusRes.data,
          recentEvents: metricsRes.data.recentEvents
        });
        
        setRecentLogs(logsRes.data); 
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching monitoring data:', error);
        setIsLoading(false);
      }
    };
  
    fetchData();
    const interval = setInterval(fetchData, refreshInterval);
  
    return () => clearInterval(interval);
  }, [refreshInterval]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const formatDuration = (ms) => {
    if (ms < 1000) return `${ms}ms`;
    return `${(ms / 1000).toFixed(2)}s`;
  };

  const formatBytes = (bytes) => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const renderActiveSessions = () => (
    <div className="sessions-container">
      <h3>Active Sessions ({metrics?.activeSessions?.length || 0})</h3>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>User</th>
              <th>Role</th>
              <th>IP Address</th>
              <th>Last Activity</th>
              <th>User Agent</th>
            </tr>
          </thead>
          <tbody>
            {metrics?.activeSessions?.map((session) => (
              <tr key={session.id}>
                <td>{session.email}</td>
                <td>{session.role}</td>
                <td>{session.ip_address}</td>
                <td>{formatDate(session.last_activity)}</td>
                <td className="user-agent">{session.user_agent}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const renderSystemStatus = () => (
    <div className="system-status">
      <h3>System Health</h3>
      <div className="status-grid">
        <div className="status-item">
          <h4>Uptime</h4>
          <p>{formatDuration(metrics?.systemHealth?.uptime * 1000)}</p>
        </div>
        <div className="status-item">
          <h4>Database</h4>
          <p className={`status ${metrics?.systemHealth?.databaseStatus === 'connected' ? 'success' : 'failure'}`}>
            {metrics?.systemHealth?.databaseStatus}
          </p>
        </div>
        <div className="status-item">
          <h4>Memory Usage</h4>
          <p>{formatBytes(metrics?.systemHealth?.memory?.heapUsed || 0)} / {formatBytes(metrics?.systemHealth?.memory?.heapTotal || 0)}</p>
        </div>
        <div className="status-item">
          <h4>Platform</h4>
          <p>{metrics?.systemHealth?.platform} ({metrics?.systemHealth?.nodeVersion})</p>
        </div>
      </div>
    </div>
  );

  const renderOverview = () => (
    <div className="overview-container">
      <div className="stats-grid">
        <div className="stat-card">
          <h3>Active Users</h3>
          <p className="stat-number">{metrics?.activeUsers || 0}</p>
        </div>
        <div className="stat-card">
          <h3>Login Attempts (24h)</h3>
          <p className="stat-number">{metrics?.securityMetrics?.login_attempts || 0}</p>
        </div>
        <div className="stat-card">
          <h3>New Registrations (24h)</h3>
          <p className="stat-number">{metrics?.securityMetrics?.registrations || 0}</p>
        </div>
        <div className="stat-card">
          <h3>Total Events (24h)</h3>
          <p className="stat-number">{metrics?.securityMetrics?.total_events || 0}</p>
        </div>
      </div>

      {renderSystemStatus()}
      {renderActiveSessions()}

      <div className="recent-activity">
        <h3>Recent Security Events</h3>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Time</th>
                <th>Event</th>
                <th>User ID</th>
                <th>IP Address</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {recentLogs.map((log) => (
                <tr key={log.id}>
                  <td>{formatDate(log.created_at)}</td>
                  <td>{log.event_type}</td>
                  <td>{log.user_id || 'N/A'}</td>
                  <td>{log.ip_address}</td>
                  <td>
                    <span className={`status ${log.details?.statusCode === 200 ? 'success' : 'failure'}`}>
                      {log.details?.statusCode}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const renderMetrics = () => (
    <div className="metrics-container">
      <div className="performance-metrics">
        <h3>Performance Metrics</h3>
        <div className="performance-chart">
          {/* Add performance chart here using recharts */}
          <p>Average Response Time: {formatDuration(metrics?.avgResponseTime || 0)}</p>
        </div>
      </div>
    </div>
  );

  const renderSettings = () => (
    <div className="settings-container">
      <h3>Admin Settings</h3>
      <div className="settings-form">
        <div className="setting-group">
          <label>Dashboard Refresh Interval</label>
          <select 
            value={refreshInterval} 
            onChange={(e) => setRefreshInterval(Number(e.target.value))}
          >
            <option value={15000}>15 seconds</option>
            <option value={30000}>30 seconds</option>
            <option value={60000}>1 minute</option>
          </select>
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    return <div className="loading">Loading admin dashboard...</div>;
  }

  return (
    <div className="admin-dashboard">
      <header className="dashboard-header">
        <h1>Admin Dashboard</h1>
        <div className="header-controls">
          <div className="refresh-info">
            Auto-refresh: {refreshInterval / 1000}s
          </div>
          <button onClick={handleLogout} className="logout-btn">
            Logout
          </button>
        </div>
      </header>

      <nav className="dashboard-nav">
        <button 
          className={activeTab === 'overview' ? 'active' : ''} 
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </button>
        <button 
          className={activeTab === 'metrics' ? 'active' : ''} 
          onClick={() => setActiveTab('metrics')}
        >
          Detailed Metrics
        </button>
        <button 
          className={activeTab === 'settings' ? 'active' : ''} 
          onClick={() => setActiveTab('settings')}
        >
          Settings
        </button>
      </nav>

      <main className="dashboard-content">
        {activeTab === 'overview' && renderOverview()}
        {activeTab === 'metrics' && renderMetrics()}
        {activeTab === 'settings' && renderSettings()}
      </main>
    </div>
  );
}

export default AdminDashboard;