export class IOSPWAHandler {
    constructor(vendor, subUrl) {
      this.vendor = vendor;
      this.subUrl = subUrl;
      this.lastManifestUpdate = 0;
      this.updateThreshold = 5000; // 5 seconds between updates
      this.isIOS = this.checkIsIOS();
      
      // Set base URLs for different purposes
      this.apiBaseUrl = process.env.REACT_APP_API_URL;
      this.appBaseUrl = process.env.REACT_APP_FRONTEND_URL || window.location.origin;
      
      // Debug initialization
      console.log('IOSPWAHandler initialized:', {
        vendor: this.vendor.business_name,
        subUrl: this.subUrl,
        isIOS: this.isIOS,
        apiBaseUrl: this.apiBaseUrl,
        appBaseUrl: this.appBaseUrl
      });
    }

    checkIsIOS() {
        // Get the user agent
        const ua = window.navigator.userAgent.toLowerCase();
        
        // Check if it's a real iOS device
        const isIOS = (
          /iphone|ipad|ipod/.test(ua) && 
          !window.MSStream && // Excludes IE11
          ('standalone' in window.navigator) && // Only real iOS has this
          // Additional checks for development environment
          !(
            /windows|linux|android/.test(ua) || // Exclude known non-iOS platforms
            (ua.includes('safari') && !ua.includes('mobile')) || // Exclude desktop Safari
            ua.includes('chrome') // Exclude Chrome's iOS user agent spoofing
          )
        );
      
        // Check if it's Safari (not another browser on iOS)
        const isSafari = /safari/.test(ua) && !ua.includes('chrome');
        
        // Check if not already installed
        const isStandalone = window.navigator.standalone;
      
        // Debug logging
        console.log('iOS Detection:', {
          userAgent: ua,
          isIOS,
          isSafari,
          isStandalone,
          hasStandaloneProperty: 'standalone' in window.navigator,
          platform: window.navigator.platform
        });
      
        // Only return true for iOS Safari that's not already installed
        return isIOS && isSafari && !isStandalone;
      }
  
    getIconUrl(size) {
      try {
        if (!this.vendor.logo_url) {
          const encodedName = encodeURIComponent(this.vendor.business_name);
          return `https://ui-avatars.com/api/?name=${encodedName}&size=${size}&background=random`;
        }
  
        let url = this.vendor.logo_url.replace(/^http:/, 'https:');
        
        if (url.includes('cloudinary.com')) {
          return url.replace(
            '/upload/',
            `/upload/c_fit,w_${size},h_${size},f_auto,q_auto/`
          );
        }
        
        return url;
      } catch (error) {
        console.error('Error generating icon URL:', error);
        return `/default-icon-${size}.png`;
      }
    }
  
    addIOSMetaTags() {
        if (!this.isIOS) return;
      
        // Remove existing meta tags first
        document.querySelectorAll('meta[name^="apple-"], meta[name="viewport"]')
          .forEach(tag => tag.remove());
      
        const metaTags = [
          { name: 'apple-mobile-web-app-capable', content: 'yes' },
          { name: 'apple-mobile-web-app-status-bar-style', content: 'default' },
          { name: 'apple-mobile-web-app-title', content: this.vendor.business_name },
          { name: 'viewport', content: 'width=device-width, initial-scale=1, viewport-fit=cover, user-scalable=no' },
          { name: 'mobile-web-app-capable', content: 'yes' },
          { name: 'theme-color', content: '#000000' },
          // Force full URL for start page
          { name: 'apple-touch-startup-image', content: `${this.appBaseUrl}/vendor/${this.subUrl}/` }
        ];
      
        metaTags.forEach(tag => {
          const meta = document.createElement('meta');
          meta.name = tag.name;
          meta.content = tag.content;
          document.head.appendChild(meta);
        });
      
        // Remove existing apple touch icons
        document.querySelectorAll('link[rel^="apple-touch-icon"]').forEach(link => link.remove());
      
        // Add apple touch icons with specific sizes
        [180, 192, 512].forEach(size => {
          const link = document.createElement('link');
          link.rel = 'apple-touch-icon';
          link.sizes = `${size}x${size}`;
          link.href = this.getIconUrl(size);
          document.head.appendChild(link);
        });
      
        // Add startup image with full URL
        const splashLink = document.createElement('link');
        splashLink.rel = 'apple-touch-startup-image';
        splashLink.href = `${this.appBaseUrl}/vendor/${this.subUrl}/`;
        document.head.appendChild(splashLink);
      }
  
    updateManifest() {
        if (!this.isIOS) return false;
      
        const now = Date.now();
        // Increased threshold to 5 minutes (300000ms) to reduce updates
        if (this.lastManifestUpdate && (now - this.lastManifestUpdate < 300000)) {
          console.log('Skipping manifest update - using cached version');
          return false;
        }
        
        this.lastManifestUpdate = now;
      
        // Remove existing manifest link
        const manifestLink = document.querySelector('link[rel="manifest"]');
        if (manifestLink) {
          manifestLink.remove();
        }
      
        // Create new manifest link with cache busting
        const newLink = document.createElement('link');
        newLink.rel = 'manifest';
        newLink.href = `${this.apiBaseUrl}/manifest/${this.subUrl}?t=${now}`;
        newLink.setAttribute('crossorigin', 'use-credentials');
      
        // Add manifest link to head
        document.head.appendChild(newLink);
      
        // Create local manifest data as fallback
        const manifestData = {
          name: `${this.vendor.business_name} Loyalty`,
          short_name: this.vendor.business_name,
          start_url: `/vendor/${this.subUrl}/`,
          scope: '/',
          display: 'standalone',
          background_color: '#ffffff',
          theme_color: '#000000',
          icons: [
            {
              src: this.getIconUrl(180),
              sizes: '180x180',
              type: 'image/png',
              purpose: 'any'
            },
            {
              src: this.getIconUrl(192),
              sizes: '192x192',
              type: 'image/png',
              purpose: 'any'
            },
            {
              src: this.getIconUrl(512),
              sizes: '512x512',
              type: 'image/png',
              purpose: 'any'
            }
          ],
          id: `/vendor/${this.subUrl}`,
          description: `Loyalty program for ${this.vendor.business_name}`,
          orientation: 'any',
          categories: ['business', 'loyalty']
        };
      
        // Create blob URL as fallback
        const blob = new Blob([JSON.stringify(manifestData)], { type: 'application/manifest+json' });
        const manifestUrl = URL.createObjectURL(blob);
      
        // Set as fallback if remote manifest fails
        newLink.onerror = () => {
          console.log('Remote manifest failed, using local fallback');
          newLink.href = manifestUrl;
        };
      
        // Debug info
        console.log('Manifest updated:', {
          remoteUrl: newLink.href,
          localUrl: manifestUrl,
          manifestData,
          timestamp: now
        });
        this.debugManifestStatus();
      
        return true;
      }
  
    showInstallInstructions() {
      if (!this.isIOS) return false;
  
      return {
        title: `Install ${this.vendor.business_name} App`,
        steps: [
          'Tap the Share button in your browser (□↑)',
          'Scroll down and tap "Add to Home Screen"'
        ],
        image: '/ios-install-guide10.jpg'
      };
    }
  
    debugManifestStatus() {
      console.log('iOS PWA Debug Info:', {
        isIOS: this.isIOS,
        vendorName: this.vendor.business_name,
        subUrl: this.subUrl,
        apiBaseUrl: this.apiBaseUrl,
        appBaseUrl: this.appBaseUrl,
        lastUpdate: this.lastManifestUpdate,
        manifestLink: document.querySelector('link[rel="manifest"]')?.href,
        metaTags: {
          capable: document.querySelector('meta[name="apple-mobile-web-app-capable"]')?.content,
          title: document.querySelector('meta[name="apple-mobile-web-app-title"]')?.content
        },
        icons: Array.from(document.querySelectorAll('link[rel="apple-touch-icon"]'))
          .map(link => ({
            sizes: link.sizes,
            href: link.href
          })),
        isStandalone: window.navigator.standalone === true
      });
    }
}